@use '../abstracts' as *;

/* lps_parts--flow
********************************************** */
.lps_parts--flow .flow_item {
  position: relative;
  max-width: 860px;
  @include auto-margin;
  padding-left: 86px;
  counter-increment: number;
  @include mq(sp) {
    padding-left: 56px;
  }
}
.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: 64px;
  @include mq(sp) {
    padding-bottom: 20px;
  }
}
.lps_parts--flow .ttl-03 {
  font-size: 2rem; /* 32px */
  color: var(--clr-main, #009140);
  @include mq(sp) {
    font-size: 1.429rem; /* 20px */
  }
  &::after {
    content: '';
    position: absolute;
    top: -18px;
    left: -118px;
    width: 105px;
    height: 83px;
    background-size: 100% auto;
    @include mq(pc) {
      top: -8px;
      left: -105px;
      width: 85px;
      height: 66px;
    }
    @include mq(sp) {
      top: -6px;
      left: -66px;
      width: 54px;
      height: 43px;
      font-size: 1.286rem; /* 18px */
    }
  }
}
@for $i from 1 to 9 {
  .lps_parts--flow .flow_item:nth-child(#{$i}) .ttl-03::after {  
    background-image: url(../images/common/txt_flow_0#{$i}.svg);
  }
}
.lps_parts--flow .flow_item:not(:last-child)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 26px;
  display: block;
  width: 0;
  height: calc(100% - 19px);
  border-right: 1px dashed var(--clr-main, #009140);
  @include mq(sp) {
    top: 36px;
    left: 18px;
    height: calc(100% - 48px);
  }
}
.lps_parts--flow .txt-wh .ttl-03 {
  color: #fff;
}
.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: #fff;
}
.lps_parts--flow .txt-wh .ttl-03::after {
  color: var(--clr-main, #009140);
  background-color: #fff;
}
.lps_parts--flow .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
  @include mq(sp) {
    float: none !important;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 16px !important;
  }
}
