@use '../abstracts' as * ;

/* ##############################################################################

    FOOTER

############################################################################## */

  .footer {
    background-image: linear-gradient(90deg,
      #3c3939 25%, #363333 25%, #363333 50%, #3c3939 50%, #3c3939 75%,#363333 75%, #363333);
    background-size: 8px 8px;
    color: #fff;
    a {
      color: #fff;
    }
    .btn a {
      background-color: var(--bg-main);
    }
    .btn a:hover {
      background-color: var(--btn-bg-cta-hvr);
    }
  }
  .footer_top > .inner {
    display: grid;
    grid-template-columns: auto auto;
    align-content: unset;
    grid-gap: 4vw;
    justify-content: center;
    @include mq(1200) {
      grid-template-columns: auto;
    }
  }

/* footer cta
********************************************** */
.footer--cta {
  background-image: url(../images/common/contact_bg_01.jpg);
  padding-bottom: 56px;
  background-position: center;
  body:not(.home) & {
    padding-top: 112px;
    @include mq(med) {
      padding-top: 72px;
    }
  }
  @include mq(sp) {
    background-image: url(../images/common/contact_bg_01_sp.webp);
    background-size: auto 100%;
  }
}
.footer--cta .inner {
  max-width: 720px;
}
.footer--cta--box {
  position: relative;
  background-color: rgba(255, 255, 255, .4);
  box-shadow: 0 10px 10px 0px rgb(0 0 0 / 37%);
  padding: min(64px, 4vw);
  padding-top: 106px;
  border-radius: 16px;
  @include mq(med) {
    padding-top: 52px;
  }
  h2 {
    position: absolute;
    top: -0.75em;
    left: 0;
    right: 0;
    margin: auto;
    font-size: rem(160);
    @include mq(med) {
      font-size: min(80px,18vw);
    }
  }
}
.footer--cta--catch {
  background-color: #fff;
  width: fit-content;
  margin: 0 auto 16px;
  padding: 24px 40px;
  border-radius: 8px;
  @include mq(med) {
    padding: 12px 24px;
  }
}
.footer--tel {
  display: grid;
  place-content: center;
  a {
    display: grid !important;
    place-content: center;
    text-align: center;
  }
  .tel {
    padding: 0 16px;
    border-bottom: 1px dotted var(--clr-main);
    line-height: 1.4;
    margin-bottom: 10px;
  }
  @include mq(med) {
    .tel {
      font-size: sprem(20);
    }
    .num {
      font-size: sprem(30);
    }
  }
}


/* footer_top
********************************************** */
  /* --- info --- */
  .footer--info {
    @include mq(1200) {
      text-align: center;
    }
    @include mq(sp) {
      margin-bottom: 16px;
    }
  }

  /* ---fnav --- */
  .fnav {
    max-width: 1000px;
    width: 60vw;
    @include mq(1200) {
      width: 100%;
    }
  }
  .fnav--menu {
    display: grid;
    grid-template-rows: auto auto auto 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-flow: column;
    grid-row-gap: 10px;
    margin-bottom: 40px;
    #menu-item-3279 { //お取扱い商品
      grid-row: span 4;
    }
    #menu-item-3279 a {
      margin-bottom: 10px;
    }
    #menu-item-3273 { //よくあるご質問
      grid-row: span 2;
    }
    @include mq(sp) {
      grid-template-rows: auto auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-flow: row;
      #menu-item-3279 { //お取扱い商品
        grid-row: span 1;
        .sub-menu a {
          padding-left: 8px;
        }
      }
      #menu-item-3273 { //よくあるご質問
        grid-row: span 1;
      }
      #menu-item-3279 a {
        margin-bottom: 0;
      }
    }
  }

  .fnav--menu a {
    display: block;
    padding: 0 1em;
    &:not([href=""]):hover {
      opacity: .7;
    }
    &[href=""] {
      cursor: default;
      pointer-events: none;
    }
    @include mq(sp) {
      position: relative;
      padding: .3em .5em .3em 0;
    }
  }
  .footer .sub-menu a::before {
    content: '−';
    color: var(--clr-txt-sub);
    margin-right: 8px;
  }
  .fnav--line {
    .btn {
      margin-bottom: 0;
      margin-right: 16px;
    }
    @include mq(sp) {
      .btn {
        margin-right: 0;
      }
      p {
        margin-top: 16px;
      }
    }
  }

  /* ---sns_area --- */
  .sns_area {
    margin-top: 40px;
    @include mq(sp) {
      margin-top: 24px;

    }
  }
  .sns--list li:not(:last-child) {
    margin-right: .5rem;
  }
  .sns--list a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem; /* 24px */
  }
  .sns--list a:hover {
    opacity: .7;
  }

/* footer_btm
*************************************************** */
  .footer_btm {
    padding: 8px 0;
  }
  .pbl a {
    opacity: .5;
    &:hover {
      opacity: 1;
    }
  }

  @include mq(sp) {
    .copyright {
      margin-right: 1em;
    }
  }
