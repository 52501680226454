@use '../abstracts' as *;
@forward './archive';
@forward './page';
@forward './search';
@forward './single';
@forward './sitemap';



/* top common
 ############################### */
.section_ttl-top {
  position: relative;
  width: fit-content;
  font-size: rem(48);
  font-family: var(--font-jp-b);
  letter-spacing: var(--ltr-space-ttl);
  overflow: hidden;
  padding: 0 0.3em 40px 0.3em;
  span {
    position: absolute;
    right: 28px;
    display: block;
    font-size: rem(16);
    font-family: var(--font-en);
    color: var(--clr-main);   
    letter-spacing: var(--ltr-space-default);
    font-weight: 500;
    &::before {
      content: '';
      position: absolute;
      top: 0.8em;
      left: -1010px;
      width: 1000px;
      height: 4px;
      background-color: var(--clr-main);
    }
    &::after {
      content: '';
      position: absolute;
      top: 0.8em;
      right: -1010px;
      width: 1000px;
      height: 4px;
      background-color: var(--clr-main);
    }
  }
  @include mq(sp) {
    font-size: sprem(30);
    padding: 0 0 32px 0;
  }
}
.section_ttl-top.section_ttl-top-sm { //ボーダーなし
  margin-left: auto;
  margin-right: auto;
  span {
    position: relative;
    right: auto;
    text-align: center;
  }
  span::before,
  span::after {
    display: none;
  }
}

@include mq(sp) {
  body.home {
    padding-top: 0;
  }
}

/* insurance 
 ############################### */
 @include mq(sp) {
  .top--insurance {
    background: linear-gradient(180deg, rgb(255 255 255) 0%, rgba(255,255,255,1) 130px, rgba(243,246,204,1) 130px);
    padding-top: 20px;
    padding-bottom: 158px;
  }
 }
 .top_map_area {
  padding-bottom: 260px;
  @include mq(sp) {
    padding-bottom: 0;
  }
 }
.top--insurance {
  .section_ttl-top,
  .top--insurance--menu {
    margin-left: 2%;
  }
}
.top--insurance--menu {
  height: 80px;
  align-items: flex-end;
  & > div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(24);
    width: min(400px, 33%);
    height: 72px;
    background-color: #8a8888;
    color: #fff;
    border-radius: 24px 24px  0 0;
    margin-right: 1px;
    transition: var(--transit-default);
    transition-duration: .2s;
    cursor: pointer;
    @include mq(sp,min) {
      &::after {
        content: '';
        position: absolute;
        right: 26px;
        display: block;
        width: 11px;
        height: 11px;
        border-right: 2px solid #ffffff;
        border-top: 2px solid #ffffff;
        transform: rotate(45deg);
        margin-top: 0;
        margin-bottom: 0;
      }  
    }
    @include mq(1200) {
      font-size: rem(20);
    }
    @include mq(sp) {
      font-size: sprem(16);
      line-height: 1.2;
      text-align: center;
    }
  }
  & > div.active {
    height: 80px;
    background-color: #30a649;
  }
}
.top--insurance--content {
  & > div {
    display: none;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 16px;
    padding: 48px;
    @include mq(sp) {
     padding: 20px; 
      background-color: rgba(255, 255, 255, 0.45);
    }
  }
  & > div.active{
    display: block;
  }
}

/* business */
.business_list {
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 1fr; 
  gap: 32px 32px; 
  @include mq(1600) {
    grid-template-columns: 1fr 1fr 1fr; 
  }
  @include mq(1100) {
    grid-template-columns: 1fr 1fr; 
  }
  @include mq(sp) {
    grid-template-columns: 1fr; 
    gap: 16px;
  }
  & > li {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    padding: clamp(24px,1.94vw,35px);
    overflow: hidden;
  }
  .business_icon {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
    transition: .45s cubic-bezier(0.52, 2.96, 0.44, 0.71);
    @include mq(sp) {
      width: 100px;
    }
  }
  li:hover .business_icon  {
    transform: scale(1.08);
  }
  h4 {
    text-align: center;
    font-family: var(--font-jp-b);
    font-size: clamp(rem(24),1.6vw,rem(28));
    margin-bottom: 16px;
  }
  p {
    font-size: rem(15);
    margin-bottom: 24px;
  }
  .business--all {
    background-color: #007c3e;
    h4 {
      color: #c3d000;
    }
    .business_list--arrow {
      background-color: #c3d000;
    }
  }
}
.business_list--arrow {
  position: absolute;
  display: flex;
  align-items: center;
  right: -30px;
  bottom: -30px;
  transform: rotate(45deg);
  width: 60px;
  height: 60px;
  opacity: .5;
  transition: .3s;
  img {
    transform: rotate(-45deg);
    width: 20px;
    height: 20px;
    color: #fff;
  }
  li:hover & {
    opacity: 1;
  }
}
.business_child_list {
  font-family: var(--font-jp-b);
  font-size: rem(20);
  span {
    display: flex;
    align-items: center;
    border-bottom: 1px dotted var(--clr-main);
    padding: 4px 2px 4px 2px;
    line-height: 1.4;
  }
  span::before {
    margin-right: 8px;
  }
  span::after {
    position: absolute;
    right: 8px;
  }
  .business--all & {
    color: #fff;
    span {
      border-bottom: 1px dotted #c3d000;
    }
  }
}

/* person */
.top--insurance--content .insurance-person {
  position: relative;
  & > p {
    margin-bottom: 80px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 40px;
    left: 40px;
    width: min(202px,13.4vw);
    height: 188px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url('../images/index/insurance_img_person01.png');
    z-index: 2;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50px;
    right: 40px;
    width: min(203px,13.4vw);
    height: 168px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url('../images/index/insurance_img_person02.png');
    z-index: 2;
  }
  @include mq(sp) {
    &::before,
    &::after {
      top: 133px;
    }
  }
}
.person--wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 27px;
  @include mq(sp) {
    grid-template-columns: 1fr;
    grid-gap: 76px;
  }
}
.person--wrap .person--content {
  position: relative;
  background-color: #fff;
  padding: 148px 2vw 2vw;
  border-radius: 16px;
  @include mq(sp) {
    padding: 88px 2vw 2vw;
  }
}
.person_ttl {
  text-align: center;
  position: absolute;
  top: -80px;
  left: 0;
  right: 0;
  width: 224px;
  height: 225px;
  margin: auto;
  padding-top: 48px;
  background-color: #fff;
  border-radius: 50%;
  @include mq(sp) {
    top: -62px;
    width: 144px;
    height: 144px;
    padding-top: 27px;
  }
  img {
    margin-bottom: 8px;
    @include mq(sp) {
      height: 54px;
      width: auto;
    }
  }
  h4 {
    font-size: rem(32);
    @include mq(sp) {
      font-size: sprem(22);
    }
  }
  .person--content-damage & {
    color: #14b062;
  }
  .person--content-life & {
    color: #f15d5d;
  }
}
.person--wrap .person_list {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  row-gap: 8px;
  flex-wrap: wrap;
  color: #fff;
  li {
    display: flex;
    width: calc(100% / 3 - 16px / 3);
    flex-direction: column;
    align-items: center;
    padding: 1.6vw 0.6vw 1vw;
    background-color: var(--clr-main);
    border-radius: 8px;
    @include mq(sp) {
      width: calc(100% / 2 - 16px / 2);
      padding: 12px 8px 12px;
    }

  }
  .person_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    width: 7vw;
    height: 7vw;
    background-color: #fff;
    border-radius: 505%;
    @include mq(sp) {
      width: 16vw;
      height: 16vw;
      margin-bottom: 8px;
    }
  }
  img {
    width: 80%;
    height: 57%;
    object-fit: contain;
  }
  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: min(1.375rem, 1.2vw);
    height: 2.36em;
    line-height: 1.2;
    font-family: var(--font-jp-b);
    span {
      font-size: 70%;
      margin-top: 0.1em;
    }
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
}
.person--content-damage li {
  &:nth-child(1) { background-color: #009141;}
  &:nth-child(2) { background-color: #14b062;}
  &:nth-child(3) { background-color: #44c082;}
  &:nth-child(4) { background-color: #308820;}
  &:nth-child(5) { background-color: #519b43;}
  &:nth-child(6) { background-color: #6db15f;}
}
.person--content-life li {
  &:nth-child(1) { background-color: #f15d5d;}
  &:nth-child(2) { background-color: #fb8080;}
  &:nth-child(3) { background-color: #f89b9b;}
  &:nth-child(4) { background-color: #f15d6e;}
  &:nth-child(5) { background-color: #f97988;}
  &:nth-child(6) { background-color: #ff97a3;}
}
@include mq(sp) {
  .person--content-damage li {
    &:nth-child(1) { background-color: #009141;}
    &:nth-child(3) { background-color: #14b062;}
    &:nth-child(5) { background-color: #44c082;}
    &:nth-child(2) { background-color: #308820;}
    &:nth-child(4) { background-color: #519b43;}
    &:nth-child(6) { background-color: #6db15f;}
  }
  .person--content-life li {
    &:nth-child(1) { background-color: #f15d5d;}
    &:nth-child(3) { background-color: #fb8080;}
    &:nth-child(5) { background-color: #f89b9b;}
    &:nth-child(2) { background-color: #f15d6e;}
    &:nth-child(4) { background-color: #f97988;}
    &:nth-child(6) { background-color: #ff97a3;}
  } 
}

/* teacher */
.top--insurance--content .insurance-teacher {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: 40px;
    left: 40px;
    width: min(350px,18.2vw);
    height: 155px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url('../images/index/insurance_img_person03.png');
    z-index: 2;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 50px;
    right: 40px;
    width: min(233px,12.1vw);
    height: 151px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url('../images/index/insurance_img_person04.png');
    z-index: 2;
  }
  @include mq(pc) {
    &::before {
      content: '';
      position: absolute;
      top: 26px;
      bottom: auto;
      width: min(350px, 17.2vw);
    }
    &::after {
      content: '';
      bottom: 20px;
      right: 21px;
      width: min(233px, 12.1vw);
      height: 111px;
    }
  }
  @include mq(sp) {
    &::before,
    &::after {
      display: none;
    }
  }
}
.insurance-teacher .teacher--wrap {
  @include mq(pc) {
    flex-direction: column;
  }
}
.insurance-teacher h3 {
  font-size: min(rem(40),2vw);
  @include mq(pc) {
    text-align: center;
  }
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.teacher_txt {
  margin-right: min(80px,5vw);
  width: 43%;
  @include mq(pc) {
    margin-right: 0;
    margin-bottom: 40px;
    width: 100%;
    max-width: 860px;
  }
  @include mq(sp) {
    width: 100%;
  }
}
.teacher_item_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  @include mq(sp) {
    grid-template-columns: 1fr;
  }
  li {
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5vw 1vw;
    font-size: rem(24);
    font-family: var(--font-jp-b);
    border-radius: 16px;
    width: 15.1vw;
    overflow: hidden;
    @include mq(pc) {
      width: auto;
      padding: 2.5vw 3.3vw;
    }
    @include mq(sp) {
      flex-direction: row;
    }
  }
  .teacher_icon {
    text-align: center;
  }
  .teacher_icon img {
    max-width: 75%;
    margin-bottom: 16px;
    transition: .45s cubic-bezier(0.52, 2.96, 0.44, 0.71);
    @include mq(sp) {
      width: 120px;
      margin-bottom: 0;
    }
  }
  li:hover .teacher_icon img {
    transform: scale(1.08);
  }
  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: clamp(rem(20),1.4vw,rem(24));
    height: 2.36em;
    line-height: 1.4;
    span {
      font-size: 80%;
    }
    @include mq(sp) {
      text-align: left;
    }
  }
}
@include mq(sp) {
  .teacher_txt--box h5 {
    font-size: sprem(18);
  }
}

/* reason 
 ############################### */
  .home #wrapper::before {
    content: '';
    position: absolute;
    top: -188px;
    left: 0;
    width: 100%;
    height: 190px;
    background-image: url(../images/index/hero_bg_sec_wh.svg);
    background-size: auto 300px;
    background-position: top;
  }
@include mq(sp) {
  .home #wrapper::before {
    width: 100%;
    height: 130px;
    top: -129px;
    background-size: auto 170px;
  }
  //SPでは保険情報の上が背景の切れ目になる
  .top--insurance::before {
    content: '';
    position: absolute;
    top: -129px;
    left: 0;
    width: 100%;
    height: 130px;
    background-image: url(../images/index/hero_bg_sec_wh.svg);
    background-size: auto 170px;
    background-position: top;
  }
}
.top--reason {
  padding-top: 20px;
  overflow: hidden;
}
.top--reason .section_ttl-top {
  margin-right: 100px;
  flex-shrink: 0;
}
.top--reason .slide_wrap {
  width: 85%;
  max-width: 1360px;
}
.reason_list {
  position: relative;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: min(448px,23.3vw);
  height: 450px !important;
  padding: min(48px, 3vw);
  @include mq(sp) {
    height: 360px !important;
    padding: 32px;
  }
  .reason_list--icon {
    margin-bottom: 64px;
    transition: transform 0.5s cubic-bezier(0.02, 0.82, 0.58, 1.64);
    @include mq(sp) {
      margin-bottom: 44px;
    }
  }
  &:hover .reason_list--icon {
    transform: scale(1.1);
  }
  h3 {
    position: relative;
    text-align: center;
    font-size: min(rem(28), 1.7vw);
    font-family: var(--font-jp-b);
    margin-bottom: 16px;
    @include mq(sp) {
      font-size: sprem(20);
    }
    &::before {
      content: '';
      position: absolute;
      top: -27px;
      left: 0;
      right: 0;
      margin: auto;
      display: block;
      width: 32px;
      height: 2px;
      background-color: var(--clr-main);
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(../images/common/pt_bg_02.jpg);
    z-index: -1;
    border-radius: 16px;
    border: 2px solid transparent;
    transition: .2s ease-out;
  }
  &:hover::before {
    border: 2px solid var(--clr-main);
  }
  @include mq(1025,min) {
    &.swiper-slide-next::before {
      height: 110%;
      margin: auto;
      border: 2px solid var(--clr-main);
    }
  }
  @include mq(sp) {
    img {
      height: 80px;
      margin: auto;
    }
  }
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: grid;
  place-content: center;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--clr-main);
  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border: solid #fff;
    border-width: 1px 1px 0 0;
  }
  &::after {
    display: none;
  }
}
.swiper-button-prev {
  left: -3rem;
}
.swiper-button-next {
  right: -3rem;
}
.swiper-button-prev::before {
  margin-left: 0.4rem;
  transform: rotate(-135deg);
}
.swiper-button-next::before {
  margin-right: 0.4rem;
  transform: rotate(45deg);
}

/* customer 
 ############################### */
.top--customer .section_ttl-top {
  overflow: visible;
  @include mq(sp) {
    width: 100%;
    text-align: center;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 8px;
    left: -120px;
    display: block;
    width: 64px;
    height: 72px;
    background-image: url(../images/illust/illust_tree_06.svg);
    background-size: 100% auto;
  }
  &::after {
    left: auto;
    right: -120px;
  }
  @include mq(sp) {
    &::before,
    &::after {
      width: 44px;
      height: 50px;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
}
.top--customer .customer_box {
  border-radius: 16px;
  padding: min(64px,4vw) 24px;
}
.top--customer .customer_box--data {
  & > div {
    padding: 0 20px;
    @include mq(sp) {
      padding: 20px 0;
    }
  }
  & > div:first-child {
    border-right: 2px solid var(--clr-main);
    @include mq(sp) {
      border-right: none;
      border-bottom: 2px solid var(--clr-main);
    }
  }
  .customer_box--data--num {
  }
  li {
    line-height: 1.5;
    letter-spacing: .05em;
  }
  li:first-child {
    margin-right: 24px;
  }
  span {
    font-size: min(rem(64),4vw);
    letter-spacing: .05em;
    margin: 0 4px;
  }
  @include mq(pc) {
    .fz-24 {
      font-size: rem(20);
    }
  }
  @include mq(1150) {
    .customer_box--data--num {
      flex-direction: column;
      align-items: center;
    }
    h2 {
      margin-bottom: 16px;
    }
    li:first-child {
      margin-right: 0;
    }
  }
  @include mq(sp) {
    span {
      font-size: sprem(32);
      line-height: 1.3;
    }
  }
}
.top--customer .customer_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: min(48px,3vw);
  row-gap: 56px;
  -webkit-mask-image: linear-gradient(to top, transparent -10%, #7e7e7e 100%);
  mask-image: linear-gradient(to top, transparent -10%, #7e7e7e 100%);
  .customer_list--type {
    background-color: var(--bg-sub);
    border-radius: 8px;
    padding: 8px;
    text-align: center;
    margin-bottom: 32px;
  }
  .customer_list--name {
    text-align: center;
    font-size: rem(20);
  }
  @include mq(pc) {
    grid-template-columns: 1fr 1fr 1fr;
    .customer_list--name {
      font-size: rem(16);
    }
  }
  @include mq(sp) {
    grid-template-columns: 1fr 1fr;
    row-gap: 36px;
    .customer_list--type {
      margin-bottom: 16px;
    }
  }
}
@include mq(sp) {
  .customer_box--data {
    flex-direction: column;
  }
  .top--customer .customer_box--data > div {
    width: 100%;
  }
  .customer_list li:nth-last-child(1),
  .customer_list li:nth-last-child(2) {
    display: none;
  }
}

/* voice
 ############################### */
 .top--voice .post-voice {
  padding-top: 32px;
 }
 .top--voice .post-voice::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -20px;
  width: min(200px, 12.5vw);
  height: min(60px, 3.75vw);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left center;
  z-index: 1;
  background-image: url(../images/index/voice_txt_01.svg);
  @include mq(sp) {
    top: 0px;
    left: -20px;
    width: 169px;
    height: 51px;
  }
 }
 .top--voice .post-voice:nth-child(2)::before {
  background-image: url(../images/index/voice_txt_02.svg);
 }
 .top--voice .post-voice:nth-child(3)::before {
  background-image: url(../images/index/voice_txt_03.svg);
 }
 @include mq(sp) {
  .top--voice .posts {
    flex-wrap: nowrap;
    width: fit-content;
    article {
      width: 75vw !important;
    }
    article:last-child {
      margin-right: 32px;
    }
  }
  .top--voice--scroll {
    overflow: scroll;
    width: 100vw;
    position: relative;
    left: -5vw;
    padding: 0 10vw;
    margin-bottom: 16px;
  }
 }

/* content
 ############################### */
.content_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
  h3 {
    font-size: min(rem(40),2.5vw);
    margin-bottom: min(24px,1.5vw);

  }
  p {
    margin-bottom: min(56px,3.5vw);
  }
  &--item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: min(448px, 26vw);
    background-size: cover;
  }
  &--item:first-child {
    background-image: linear-gradient(90deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.3) 100%), url(../images/index/flow_bg_01.jpg);
  }
  &--item:nth-child(2) {
    background-image: linear-gradient(90deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.3) 100%), url(../images/index/faq_bg_01.jpg);
  }
  &--item:nth-child(3) {
    background-image: linear-gradient(90deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.3) 100%), url(../images/index/promise_bg_01.jpg);
  }
  @include mq(sp) {
    grid-template-columns: 1fr;
    row-gap: 16px;
    .content_list--item {
      height: 240px;
    }
    h3 {
      font-size: 1.5rem;
      margin-bottom: 16px;
    }
  }
}

/* thought
 ############################### */
.top--thought {
  padding-top: 120px;
  padding-bottom: 120px;
  @include mq(sp) {
    padding-bottom: 118px;
    padding-top: 40px;
  }
 }
.thought--bg {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: auto;
  transform: scale(0.8);
  opacity: 0;
  @include transition-base(all, .8s);
  @include easing-bezier();
  .active & {
    transform: scale(1);
    opacity: 1;
  }
}
.top--thought .thought--main_img {
  width: min(1140px, 68vw);
  @include mq(sp) {
    width: 95%;
  }
}
.top--thought .thought--sub_img {
  position: absolute;
  right: 0;
  top: 0;
  width: min(440px, 27.5vw);
  @include mq(sp) {
    top: auto;
    width: min(238px,50vw);
    bottom: 0;
  }
}
.top--thought .thought--wrap {
  padding-bottom: 40px;
  @include mq(1500) {
    padding-bottom: 120px;
  }
  @include mq(pc) {
    padding-bottom: 160px;
  }
  @include mq(sp) {
    position: relative;
    width: 100vw;
    left: -5vw;
    height: auto;
    padding: 0;
  }
}
.top--thought .thought--txt {
  position: absolute;
  right: 0;
  bottom: 0;
  width: min(500px, 33.5vw);
  height: auto;
  padding: min(55px, 3.6vw);
  overflow: hidden;
  border-radius: 16px;
  @include mq(sp) {
    position: relative;
    top: -20px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 85%;
    margin-left: 5%;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  & > * {
    position: relative;
  }
  h3 {
    font-size: min(rem(32), 2.13vw);
    @include mq(sp) {
      font-size: sprem(20);
    }
  }
  .thought--bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 110%;
    height: 110%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
@include mq(sp) {
  .top--thought .section_ttl-top {
    font-size: min(sprem(27),6.8vw);
  }
}

/* column
 ############################### */
 .top--column {
  overflow: hidden;
  @include mq(sp) {
    padding-top: 64px;
  }
 }
 .top--column .section_ttl-top {
  @include mq(sp) {
    font-size: sprem(27);
  }
 }
 .top--column .column_ttl_deco {
  position: relative;
  color: var(--clr-main);
  font-family: var(--font-en);
  font-size: rem(40);
  &::before {
    content: '';
    position: absolute;
    top: -8px;
    right: 0;
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(../images/common/icn_logomark_green.svg);
    background-size: 100% 100%;
    @include mq(sp) {
      top: -8px;
      right: 0px;
      width: 16px;
      height: 16px;
    }
  }
  @include mq(sp) {
    font-size: sprem(25);
  }
 }
.top--column .inner {
  max-width: 800px;
  @include mq(pc) {
    max-width: 650px;
  }
  &::before {
    content: "";
    position: absolute;
    right: -350px;
    top: 0px;
    display: block;
    width: 289px;
    height: 64px;
    background-image: url(../images/index/blog_img_03.png);
    background-size: cover;
    margin-bottom: 32px;
  }
}
.top--column .post {
  margin-bottom: 2.2rem;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid var(--clr-main);
  margin-bottom: 0;
  @include mq(sp) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  &:last-child {
    border-bottom: 1px solid var(--clr-main);
  }
  .post--img {
    width: 200px;
    height: 150px;
    @include mq(sp) {
      width: 150px;
      height: 112px;
    }
  }
}
// .top--column .post-column-top  {
//   position: relative;
//   display: flex;
//   align-items: center;
//   padding: 32px 40px;
//   border-top: 1px solid var(--clr-main);
//   overflow: hidden;
//   @include mq(sp) {
//     padding: 24px 0;
//   }
//   &:last-child {
//     border-bottom: 1px solid var(--clr-main);
//   }
//   .post--date {
//     color: var(--clr-main);
//     margin-right: 48px;
//     flex-shrink: 0;
//     @include mq(sp) {
//       margin-right: 24px;
//     }
//   }
//   .post--ttl {
//     text-overflow: ellipsis;
//     overflow: hidden;
//     white-space: nowrap;
//   }
//   &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     display: block;
//     width: 100%;
//     height: 100%;
//     background-color: var(--bg-sub);
//     transform: translateX(-40%);
//     transition: all 0.5s cubic-bezier(0.03, 0.57, 0.34, 0.94), opacity 0.3s linear;
//     opacity: 0;
//   }
//   &:hover::before {
//     opacity: 1;
//     transform: translateX(0);
//   }
// }
.column--img_left,
.column--img_right {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.column--img_left {
  left: -50%;
  height: min(400px,26vw);
  width: min(348px,25.2vw);
  img {
    box-shadow: rgb(0 0 0 / 13%) 0px 0px 15px 5px;
  }
  img:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
  }
  img:last-child {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 75%;
  }
  @include mq(pc) {
    left: -28vw;
  }
}
.column--img_right {
  right: -50%;
  box-shadow: rgb(0 0 0 / 13%) 0px 0px 15px 5px;
  @include mq(pc) {
    right: -60%;
  }
}

/* staff
 ############################### */
.top--staff {
  overflow: hidden;
  margin-bottom: 148px;
  @include mq(sp) {
    margin-bottom: 110px;
    margin-top: 40px;
  }
}
.staff_slide .swiper-wrapper { transition-timing-function:linear; }
.staff_slide .swiper-slide {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  width: min(448px,26.3vw);
  @include mq(sp) {
    width: 45vw;
  }
}

/* point
 ############################### */
.top--point {
  position: relative;
  padding-top: 50px;
  padding-bottom: 16px;
  margin-bottom: 120px;
  @include mq(sp) {
    margin-top: 20px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -148px;
    left: 0;
    width: 100%;
    height: 150px;
    background-image: url(../images/index/sec_bg_03.png);
    background-size: auto 150px;
    background-position: top;
    background-repeat: repeat-x;
    @include mq(sp) {
      background-image: url(../images/index/sec_bg_03.webp);
    }
  }
}
 
.top--point .point_list .point_item {
  position: relative;
  padding: 30px 30px 104px;
  @include mq(sp) {
    padding: 30px 30px 74px;
    margin-bottom: 50px;
  }
  img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -50px;
    @include mq(sp) {
      margin: auto;
      bottom: -35px;
      transform: scale(0.7);
      left: auto;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: -80px;
    left: -20px;
    width: min(177px,11vw);
    height: 141px;
    background-image: url(../images/index/thought_balloon.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: bottom;
    @include mq(sp) {
      top: -80px;
      left: -200px;
      right: 0;
      margin: auto;
      width: 113px;
      height: 141px;
    }
  }
  @include mq(sp) {
    p {
      font-size: sprem(16);
    }
  }
}

