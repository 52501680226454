/* --- position --- */
.pos_rel {
  position: relative;
}
.pos_ab {
  position: absolute;
}

/* --- float --- */
.flt-rgt { float: right; }
.flt-lft { float: left; }