@use '../abstracts' as *;

/* lps_parts--column
********************************************** */
.lps_parts--column:not(.column-1) + .lps_parts--column {
  margin-top: 0;
  @include mq(sp) {
    margin-top: 24px;
  }
}
.lps_parts--column .column_item,
.lps_parts--column .text {
  position: relative;
  display: flex;
  flex-direction: column;
}
.lps_parts--column:not(.column-1) .text {
  height: 100%;
}
.lps_parts--column .img {
  flex-shrink: 0;
}
.lps_parts--column .img + .text {
  padding-top: 24px;
}
.lps_parts--column .bg-wh {
  border-radius: 8px;
}
.lps_parts--column .bg-wh .text {
  padding: 6%;
}
.lps_parts--column .column_item:empty {
  background-color: transparent;
}
.lps_parts--column:not(.column-1) table { width: 100%; }
.lps_parts--column.column-1 .box { padding: 40px; }
.lps_parts--column.column-1 .inner-sm {
  //基本スタイルを一旦打ち消し
  max-width: var(--inner-max);
  width: var(--inner);
  .column_item {
    width: min(720px, 90%);
    margin-left: 0;
    @include mq(sp) {
      width: 100%;
    }
  }
}

//イラストをつける
.column_item.column_add_bg {
  background-image: url(../images/illust/illust_flower_01.png);
  background-size: 27px 40px;
  background-position: right 12px bottom 12px;
  background-repeat: no-repeat;
  .column-3 &,
  .column-4 &,
  .column-5 & {
    background-size: 22px 32px;
    background-position: right 9px bottom 7px;
  }
}
.column_item.column_add_bg:nth-child(even) {
  background-image: url(../images/illust/illust_flower_02.png);
  background-size: 43px 32px;
  .column-3 &,
  .column-4 &,
  .column-5 & {
    background-size: 33px 26px;
  }
}
.column_item.column_add_bg .text {
  padding: 8%;
}

//連番をつける
.lps_parts--column .column_item.column_add_num {
  padding-top: 40px;
  @include mq(sp) {
    padding-top: 16px;
  }
}
.column_item.column_add_num::before {
  content: '';
  position: absolute;
  top: 0;
  left: -20px;
  width: min(200px,12.5vw);
  height: min(60px,3.75vw);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left center;
  z-index: 1;
  @include mq(sp) {
    top: 9px;
    left: -11px;
    width: 141px;
    height: 41px;
  }
}
@for $i from 1 through 7 {
  .column_item.column_add_num.column_num-#{$i}::before {
    background-image: url(../images/common/case_txt_0#{$i}.svg);
  }
}


@include mq(sp){
  .lps_parts--column + .lps_parts--column,
  .lps_parts--column:not(.column-1) + .lps_parts--column { margin-top: 24px; }
  .lps_parts--column .lps_parts--child { display: block; }
  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }
  .lps_parts--column .column_item { width: 100%; }
  .lps_parts--column .column_item:not(:last-child) { margin-bottom: 24px; }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
  .lps_parts--column .column_item:not(:last-child) { margin-right: 0; }
  .lps_parts--column .text.bg-wh,
  .lps_parts--column.column-1 .box { padding: 24px; }
}