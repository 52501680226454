@use '../abstracts' as *;

.page_wrapper {
  position: relative;
  display: flex;
  @include mq(sp) {
    display: block;
  }
}
.page_wrapper .page_ttl {
  position: sticky;
  top: 0;
  left: 0;
  display: block;
  text-align: left;
  align-items: flex-start;
  flex-shrink: 0;
  width: min(512px,26.6vw);
  height: 100vh;
  padding-top: 180px;
  background-image: url(../images/common/bg_side_left.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  & > .inner {
    width: 85%;
    position: relative;
    text-align: left;
    padding: 0;
    padding-bottom: min(70px,7vh);
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 222px;
      height: 8px;
      background-image: url(../images/common/ttl_line_01.svg);
      @include mq(sp) {
        background-image: url(../images/common/ttl_line_sp.svg);
        width: 100%;
        height: 4px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  .page_ttl-jp {
    font-size: min(rem(56), 3.4vw);
    line-height: 1.15;
    transform: translateX(-0.07em);
  }
  &::after {
    display: none;
  }
  @include mq(sp) {
    position: relative;
    display: grid;
    grid-template-rows: repeat(3,auto);
    grid-template-columns: 1fr;
    height: auto;
    width: var(--inner-sp);
    background-image: url(../images/common/ttl_bg_logomark.png);
    background-size: 64px 90px;
    background-repeat: no-repeat;
    background-position: right 34px top 0px;
    padding-top: 16px;
    margin: auto;
    & > .inner {
      width: 100%;
      text-align: center;
      padding-bottom: 32px;
    }
    .page_ttl-jp {
      font-size: sprem(24);
    }
  }
}
.page_wrapper main {
  // width: min(1260px,67.6vw);
  width: auto;
  flex-grow: 1;
  margin-left: auto;
  @include mq(sp) {
    width: 100%;
  }
}
.page_wrapper::after {
  content: '';
  position: sticky;
  top: 0;
  right: 0;
  width: min(144px, 5.8vw);
  height: 100vh;
  background-image: url(../images/common/bg_side_right.jpg);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 100% auto;
}

/* anchor navi
################################ */
.anchor_nav .inner {
  // background-color: #ffffffc9;
  // padding: 1.6vw;
  width: 70%;
  margin-left: 7%;
  border-radius: 8px;
  margin-top: 40px;
  font-size: rem(14);
  a {
    position: relative;
    display: block;
    padding: 0.2em 0.9em;
    background-color: #fff;
    margin: 4px 0;
    width: fit-content;
    border-radius: 2px;
    font-size: 0.8125rem;
    box-shadow: #c4d100 1px 1px;
    @include transition-base();
    @include easing-bezier();
    &.current {
      padding-left: 1.5em;
      color: var(--clr-main);
    } 
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      background-color: var(--clr-main);
      border-radius: 50%;
      transform: translateX(-50%);
      @include transition-base();
      @include easing-bezier();
      @include mq(sp) {
        display: none;
      }
    }
    &.current::before {
      width: 10px;
      height: 10px;
    }
  }
}
@include mq(sp) {
  .anchor_nav .inner {
    width: 100%;
    margin-left: 0;
    border-radius: 8px;
    margin-top: 40px;
    font-size: 0.875rem;
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 12px;
      padding-bottom: 4px;
    }
    a {
      display: flex;
      align-items: center;
      box-shadow: none;
      border-bottom: 1px dotted var(--clr-main);
      border-radius: none;
      border-radius: 0;
      width: 100%;
      height: 100%;
      padding-right: 2.4em;
      padding-left: 0.8em;
    }
    a::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 6px;
      margin: auto;
      width: 16px;
      height: 16px;
      background-image: url(../images/common/icon-arrow-list.svg);
      background-size: cover;
    }
  }
}

//特定のページで非表示に
.page-promise .anchor_nav {
  display: none;
}

/* loops contents 
################################ */
.page_wrapper #section-01 {
  &::after {
    content: '';
    position: absolute;
    right: 69px;
    top: 120px;
    display: block;
    width: min(248px, 15.5vw);
    height: 349px;
    background-image: url(../images/common/ttl_bg_logomark.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    @include mq(1600) {
      opacity: .4;
    }
    @include mq(sp) {
      display: none;
    }
  }
  @include mq(sp,min) {
    padding-top: 146px;
  }
}