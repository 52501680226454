@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
.page .breadcrumbs {
  @include mq(sp,min) {
    position: absolute;
    top: 114px;
    left: 0;
    width: min(512px, 26.6vw);
    padding: 16px 0;
    z-index: 10;
    text-align: left;
    padding: 0 7.5%;
    color: var(--clr-body);
    letter-spacing: .05em;
  }
  @include mq(sp) {
    width: 100%;
    margin: auto;
    margin-top: 1em;
    font-size: sprem(12);
    color: var(--clr-body);
    grid-row: 2;
  }
}
body:not(.page) .breadcrumbs {
  position: absolute;
  top: 114px;
  left: 0;
  width: 100%;
  @include mq(sp) {
    display: block;
    bottom: auto;
    top: auto;
    margin-top: 12px;
    position: relative;
  }
}
.breadcrumbs a {
  color: var(--clr-main, #009140);
  text-decoration: underline;
}
.breadcrumbs a:hover {
  text-decoration: none;
}
.breadcrumbs .breadcrumb_last {
  display: table-cell;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}