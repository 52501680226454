@use "../abstracts" as *;

/* lps_parts--faq
********************************************** */
.lps_parts--faq .faq_item:not(:last-child) {
  margin-bottom: 40px;
  @include mq(sp) {
    margin-bottom: 16px;
  }
}
.lps_parts--faq .ttl-03,
.lps_parts--faq .mce-content-body {
  padding: 20px 48px 20px 104px;
  @include mq(sp) {
    padding: 16px 24px 16px 56px;
  }
}
.lps_parts--faq .ttl-03 {
  background-color: var(--clr-main, #009140);
  margin-bottom: 0 !important;
  font-size: 1.5rem; /* 24px */
  color: #fff;
  border-radius: 8px 8px 0 0;
  @include mq(sp) {
    font-size: sprem(18);
  }
}
.lps_parts--faq .mce-content-body {
  position: relative;
  background-color: #fff;
  border: 1px solid var(--clr-main, #009140);
  border-radius: 0 0 8px 8px;
}
.lps_parts--faq .ttl-03::after,
.lps_parts--faq .mce-content-body::after {
  position: absolute;
  left: 48px;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 2rem; /* 32px */
  -webkit-text-fill-color: transparent;
  @include mq(sp) {
    left: 20px;
    font-size: 1.857rem; /* 26px */
  }
}
.lps_parts--faq .ttl-03::after {
  content: "Q";
  top: 14px;
  -webkit-text-stroke: 2px rgba(255, 255, 255, 1);
  @include mq(sp) {
    top: 10px;
  }
}
.lps_parts--faq .mce-content-body::after {
  content: "A";
  top: 8px;
  -webkit-text-stroke: 2px var(--clr-main);
  @include mq(sp) {
    top: 6px;
  }
}
.lps_parts--faq .mce-content-body .editor-ttl2 {
  margin-top: 0;
}
.lps_parts--faq .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
  @include mq(sp) {
      float: none !important;
      margin-top: 2px;
      margin-left: 0;
      margin-right: auto;
      margin-bottom: 8px !important;
      border-radius: 8px;
  }
}
