@use "../abstracts" as *;

/* hero
********************************************** */
.hero {
  position: relative;
  height: min(960px, 50vw);
  @include mq(sp) {
    height: auto;
    padding-top: 88px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 180px;
  }
}
.top_map_area {
  position: relative;
  overflow: hidden;
}

/* hero illust */
.hero--illust_map {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  margin: auto;
  transform: translateX(-50%);
  img {
    width: 100%;
    max-width: none;
  }
  @include mq(1920, min) {
    &,
    img {
      width: 100%;
    }
  }
}
.hero--illust_tree {
  position: absolute;
  width: 96%;
  left: 0;
  right: 0;
  margin: auto;
  top: 5.9vw;
  @include mq(sp) {
    display: none;
  }
}
.hero--illust_base {
  position: absolute;
  top: -2vw;
  left: 0;
  right: 4vw;
  bottom: 0;
  width: 33vw;
  height: 34vw;
  margin: auto;
  letter-spacing: 0;
  @include mq(sp) {
    position: absolute;
    top: 69vw;
    left: 16vw;
    right: 4vw;
    bottom: auto;
    width: 73vw;
    height: 77vw;
    margin: auto;
    letter-spacing: 0;
    z-index: 20;
  }
  .hero--illust_home {
    top: 23%;
    left: -17%;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 40%;
    height: fit-content;
    transform-origin: center bottom;
    transition: transform 0.5s cubic-bezier(0.02, 0.82, 0.58, 1.64);
    img {
      transform-origin: center bottom;
      transform: scale(1, 0);
    }
    &:hover {
      transform: scale(1.08);
    }
  }
  .hero--illust_balloon {
    position: absolute;
    top: 3%;
    left: 55%;
    right: 0;
    bottom: 0;
    margin: auto;
    display: grid;
    place-content: center;
    width: 26%;
    height: 21%;
    background-image: url(../images/index/hero_balloon.svg);
    color: #dc559b;
    font-size: min(rem(28), 1.4vw);
    background-size: 100% 100%;
    letter-spacing: 0.05em;
    @include mq(sp) {
      top: -1%;
      left: 60%;
      width: 31%;
      height: 26%;
      font-size: sprem(16);
    }
  }
  @include mq(lg, min) {
    top: 6vw;
    .hero--illust_01 {
      width: 100%;
    }
  }
}
.hero--bg {
  position: absolute;
  top: 0;
  width: max(1920px, 100%);
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: none;
  opacity: 0.9;
  background-image: url(../images/index/hero_bg_01.jpg);
  background-size: 100% auto;
  @include mq(sp) {
    width: 150%;
    background-image: url(../images/index/hero_bg_01_sp.webp);
  }
}
//アニメーション
body:not(.loaded) {
  .hero--illust_base {
    transform: translateY(15px);
    opacity: 0;
  }
  .hero--illust_home img {
    opacity: 0;
  }
  .hero--illust_balloon {
    transform: rotate(-15deg);
    opacity: 0;
  }
}
body.loaded {
  .hero--illust_home img {
    animation: hero_home 1s ease-out 1.3s 1 normal forwards;
    transition: opacity 0.5s 0.8s;
    opacity: 1;
  }
  .hero--illust_balloon {
    transform-origin: left bottom;
    transform: rotate(5deg);
    transition: transform 0.9s 2.4s cubic-bezier(0, 0, 0.24, 1), opacity 0.3s 2.4s;
    opacity: 1;
    animation: hero_balloon 5s ease-out 3.3s infinite alternate none;
  }
}
@keyframes hero_home {
  0% {
    transform: scale(1, 0);
  }
  35% {
    transform: scale(0.98, 1.2);
  }
  50% {
    transform: scale(0.95, 1.22);
  }
  70% {
    transform: scale(1.02, 0.97);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes hero_balloon {
  0% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

/* hero content */
.hero--catch {
  position: relative;
  z-index: 10;
  @include mq(sp, min) {
    position: absolute;
    top: min(152px, 10vw);
    left: 4.79vw;
  }
  @include mq(sp) {
    height: 143vw;
  }
  h2 {
    font-size: min(3rem, 2.66vw);
    color: #fff;
    text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.06em;
    margin-bottom: min(40px, 2vw);
    line-height: 1.4;
    span {
      font-size: 80%;
    }
    @include mq(sp) {
      font-size: min(sprem(26), 6.7vw);
    }
  }
  p {
    font-size: clamp(rem(14), 1.13vw, rem(16));
    line-height: 2.5;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6),
      0 0 5px rgba(255, 255, 255, 0.6);
    @include mq(sp) {
      font-size: sprem(11);
      line-height: 2;
      letter-spacing: 0.05em;
    }
  }
}
.hero_slide_imgarea {
  width: 100%;
  height: 100%;
  background-repeat: repeat-y;
  background-image: url(../images/index/hero_slide_pc2.jpg);
  background-size: 100% auto;
  animation: slide_bg 60s linear infinite;
}
@include mq(sp, min) {
  .hero_slide {
    position: absolute;
    top: 0;
    right: 0;
    width: 37.8vw;
    height: calc(100% - 120px);
    overflow: hidden;
  }
  .hero_slide_wrap {
    height: 120vh;
    width: min(370px, 20vw);
    transform: translate(-50%, -50%) rotate(19deg);
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0 0.5vw; //画像の幅は19vwになる）
    background-color: #fff;
  }
}
@include mq(sp) {
  .hero_slide {
    position: relative;
    width: 100vw;
    left: -5%;
    border-top: 8px solid #fff;
    border-bottom: 8px solid #fff;
    margin-bottom: 32px;
  }
  .hero_slide_wrap {
    width: 100%;
    height: 160px;
  }
  .hero_slide_imgarea {
    background-image: url(../images/index/hero_slide_sp2.jpg);
    background-repeat: repeat-x;
    background-size: auto 100%;
    animation: slide_bg_sp 180s linear infinite;
  }
}

@keyframes slide_bg {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 240.3851vw;
  }
  //横19(vw) * 1764/403(画像比率）* 2（スクロール分）
}
@keyframes slide_bg_sp {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 0;
  }
}

/* news */
/* --- hero--news --- */
.hero--news {
  position: relative;
  display: flex;
  overflow: hidden;
  @include mq(sp, min) {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    width: min(750px, 39vw);
  }
  @include mq(lg, min) {
    width: 860px;
  }
  @include mq(sp) {
    margin-bottom: 16px;
  }
}
.hero--news--h {
  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-rl;
  width: 48px;
  border: 2px solid #fff;
}
.hero--news--h,
.hero--news--slide,
.hero--news--more {
  height: 120px;
}
.hero--news--slide {
  background-color: rgba(255, 255, 255, 0.85);
  width: calc(100% - 138px);
  @include mq(sp) {
    width: calc(100% - 48px);
  }
}
.hero--news--slide a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 120px;
  padding: 24px;
}
.hero--news--slide a span {
  margin-right: 16px;
  margin-bottom: 1px;
}
.hero--news--slide a h3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.hero--news--more {
  position: absolute;
  right: 31px;
  bottom: 28px;
  height: auto;
}
.hero--news--arrows {
  position: absolute;
  top: 31px;
  right: 26px;
  width: 45px;
  justify-content: space-between;
  div {
    content: "";
    width: 13px;
    height: 13px;
    border: solid var(--clr-main);
    border-width: 2px 2px 0 0;
  }
  .slick-prev {
    margin-left: 0.4rem;
    transform: rotate(-135deg);
  }
  .slick-next {
    margin-right: 0.4rem;
    transform: rotate(45deg);
  }
  @include mq(sp) {
    top: 21px;
    right: 17px;
  }
}
.hero--news--ui {
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.85);
  @include mq(sp) {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
  }
}

@include mq(sp) {
  .hero_slide-pc {
    display: none !important;
  }
  .hero_slide-sp {
    display: block !important;
  }
  .hero--news--h span {
    margin-top: auto;
    margin-bottom: auto;
  }
  .hero--news--more-sp {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 8px;
    border-top: 2px solid #fff;
    svg {
      margin: auto;
      width: 24px;
    }
  }
}

/* hero sp cta */
@include mq(med) {
  .cta-sp {
    display: grid;
    grid-template-columns: 40% 1fr 1fr;
    gap: 12px;
  }
  .cta-sp .gnav--sub_cta,
  .cta-sp .gnav--contact {
    position: relative;
    top: auto;
    bottom: auto;
    right: auto;
    left: auto;
  }
  .cta-sp a:not([href^="tel"]) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 136px;
    border-radius: 8px;
    span {
      font-size: sprem(16);
      line-height: 1.3;
    }
  }
  .cta-sp .gnav--contact {
    a {
      background-color: var(--btn-bg-cta-red);
    }
    img {
      width: 40px;
    }
    span {
      font-size: sprem(20);
    }
  }
  .cta-sp .gnav--line {
    img {
      width: 64px;
      margin-bottom: 4px;
    }
  }
  .cta-sp .gnav--emr {
    img {
      width: 48px;
      margin-bottom: 10px;
    }
  }
}
@include mq(sp, min) {
  .hero--sp_cta {
    display: none;
  }
}
