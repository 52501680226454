@use '../abstracts' as *;

/* contact
********************************************** */


.contact--btnarea {
  padding-top: 120px;
  padding-bottom: 0;
  @include mq(pc) {
    padding-top: 80px;
  }
  a {
    max-width: 100%;
    width: 350px;
    padding: 32px 40px;
    border-radius: 16px;
    font-size: 18px;
    margin: 16px;
    @include mq(sp) {
      width: min(350px,100%);
      margin: 0 auto 16px;
    }
  }
  a::before {
    transform: rotate(135deg);
    top: calc(50% - 8px);
  }
  .btn a::after {
    width: 32px;
    height: 32px;
  }
}

//contactだけ装飾をなくす
.page-contact .lps_sec:nth-child(2n)::before {
  display: none;
}
.page-contact .page_wrapper #section-01 {
  padding-top: 80px;
  &::after {
    display: none;
  }
  @include mq(sp) {
    padding-top: 40px;
  }
}

//mce変更
.page-contact #section-01 .ttl-02 span:not(.anchor) {
  width: fit-content;
  display: block;
  #sec-accident {
    border-bottom: 2px dashed var(--btn-clr-emr);
    &::before {
      content:'';
      display: inline-block;
      width: 1em;
      height: 1em;
      background-image: url(../images/common/icn_info_clr.svg);
      background-size: cover;
      vertical-align: -0.13em;
      margin-right: 12px;
    }
  }
  #sec-consult {
    border-bottom: 2px dashed var(--btn-bg-cta-red);
    &::before {
      content:'';
      display: inline-block;
      width: 1em;
      height: 1em;
      background-image: url(../images/common/icn_logomark_pink.svg);
      background-size: cover;
      vertical-align: -0.13em;
      margin-right: 12px;
    }
  }
}
#anchor-contact {
  padding-top: 0;
}
.page-contact #section-01 a[href^="tel"]::before {
  content: '';
  display: inline-block;
  width: 29px;
  height: 29px;
  vertical-align: -0.4em;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICA8cGF0aCBmaWxsPSIjMDA5MTQwIiBkPSJNMjY3LjI1LDEwOTguOWExLjY5LDEuNjksMCwwLDAtMi41NDQsMGMtMC41OTMuNTktMS4xODksMS4xOC0xLjc3MywxLjc4YTAuMzU1LDAuMzU1LDAsMCwxLS40OS4wOWMtMC4zODUtLjIxLTAuNzk1LTAuMzgtMS4xNjQtMC42MWExOC4zNjYsMTguMzY2LDAsMCwxLTQuNDQ4LTQuMDUsMTAuNTA2LDEwLjUwNiwwLDAsMS0xLjU5My0yLjU1LDAuMzY2LDAuMzY2LDAsMCwxLC4wOS0wLjQ3YzAuNTk0LS41OCwxLjE3NC0xLjE2LDEuNzU5LTEuNzVhMS43LDEuNywwLDAsMC0uMDA2LTIuNjFjLTAuNDY0LS40Ny0wLjkyOS0wLjkyLTEuMzk0LTEuMzlzLTAuOTU0LS45Ny0xLjQzOS0xLjQ0YTEuNjg4LDEuNjg4LDAsMCwwLTIuNTQzLDBjLTAuNi41OS0xLjE3NSwxLjItMS43ODQsMS43OGEyLjg2NiwyLjg2NiwwLDAsMC0uOTEsMS45NSw4LjI2MSw4LjI2MSwwLDAsMCwuNjQsMy41NiwyMS42MTEsMjEuNjExLDAsMCwwLDMuODQzLDYuNCwyMy43NjEsMjMuNzYxLDAsMCwwLDcuODc1LDYuMTYsMTEuNDcsMTEuNDcsMCwwLDAsNC4zNjIsMS4yNywzLjIsMy4yLDAsMCwwLDIuNzQzLTEuMDRjMC41MS0uNTgsMS4wODUtMS4wOSwxLjYyNC0xLjY0YTEuNywxLjcsMCwwLDAsLjAxLTIuNTlRMjY4LjY4NCwxMTAwLjMyNSwyNjcuMjUsMTA5OC45Wm0tMC45NTQtMy45OCwxLjg0NC0uMzJhOC4yNCw4LjI0LDAsMCwwLTIuMy00LjQ0LDguMTU3LDguMTU3LDAsMCwwLTQuNy0yLjM0bC0wLjI2LDEuODVBNi4zODYsNi4zODYsMCwwLDEsMjY2LjMsMTA5NC45MlptMi44ODMtOC4wMWExMy41NjQsMTMuNTY0LDAsMCwwLTcuNzk1LTMuODlsLTAuMjYsMS44NWExMS44NjksMTEuODY5LDAsMCwxLDEwLjAzOSw5LjczbDEuODQzLS4zMUExMy42NjgsMTMuNjY4LDAsMCwwLDI2OS4xNzksMTA4Ni45MVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNDkgLTEwODMuMDMpIj48L3BhdGg+Cjwvc3ZnPg==');
  background-size: cover;
  margin-right: 7px;
}

//formy
@include mq(sp,min) {
  .page-contact .box-shadow {
    padding: min(80px,4vw);
  }
}
@include mq(sp) {
  .contact .box-shadow {
    padding: 24px;
  }
}
.contact .cta_tel {
  width: 100%;
  max-width: 400px;
  @include auto-margin;
}
.contact .cta_tel a {
  padding: 24px 40px;
  border-radius: 16px;
  @include mq(sp) {
    padding: 18px 12px;
    border-radius: 9px;
  }
}