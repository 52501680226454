@use '../abstracts/' as *;

/* home_news
********************************************** */
.home_news {
  padding: 40px 0;
  background-color: #fff;
  border-bottom: 1px solid #EEE;
  @include mq(sp) {
    padding: 20px 5%;
  }
}
.home_news .news_list {
  flex: 1;
}
.news_list--ttl {
  display: block;
  position: relative;
  margin-right: 40px;
  padding: 0;
  line-height: 1.2;
  @include mq(sp) {
    width: auto;
    float: none;
    height: auto;
    margin-bottom: 16px;
  }
}
