@use '../abstracts' as *;

/* img
********************************************** */
.img-ctr {
  display: block;
  @include auto-margin;
}
.img-trim {
  display: flex;
  @include center-flex;
}
.img-trim.pos_rel::before {
  content: '';
  display: block;
}
.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-cover.pos_ab {
  inset: 0;
  margin: auto;
}
.img-contain {
  object-fit: contain;
}