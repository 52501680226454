@use "sass:map";

// SASS用の基本設定変数

// デザイン上の幅
$designWidth: 1920px;

// 色
$clr-main: #009140;

// font-families
$font-primary: 'Roboto', "こぶりなゴシック W3 JIS2004", "Koburina Gothic W3 JIS2004";  
$font-secondary: "ヒラギノ角ゴ オールド W3 JIS2004", "Hiragino Sans Old W3 JIS2004";
$font-secondary--light: "ヒラギノ角ゴ オールド W3 JIS2004", "Hiragino Sans Old W3 JIS2004";
$font-jp: 'Roboto', "こぶりなゴシック W3 JIS2004", "Koburina Gothic W3 JIS2004", 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
$font-jp-b: 'Roboto', "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004", 'ヒラギノ角ゴ Pro W6', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
$font-en: 'Roboto', sans-serif;
$font-noto-sans: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

// CSS変数
:root {
  // typography
  --ltr-space-default: .05em;
  --ltr-space-ttl: .1em;
  --line-height-default: 1.7;
  --line-height-hdr: 1.5;
  --line-height-none: 1;

  // font-families
  --font-primary: 'Roboto',"こぶりなゴシック W3 JIS2004", "Koburina Gothic W3 JIS2004";  
  --font-secondary: "ヒラギノ角ゴ オールド W3 JIS2004", "Hiragino Sans Old W3 JIS2004";
  --font-secondary--light: "ヒラギノ角ゴ オールド W3 JIS2004", "Hiragino Sans Old W3 JIS2004";
  --font-jp: 'Roboto',"こぶりなゴシック W3 JIS2004", "Koburina Gothic W3 JIS2004", 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  --font-jp-b: "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004", 'ヒラギノ角ゴ Pro W6', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  --font-jp-en: 'Roboto',"こぶりなゴシック W3 JIS2004", "Koburina Gothic W3 JIS2004", 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  --font-jp-en-b: 'Roboto', "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004", 'ヒラギノ角ゴ Pro W6', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  --font-en: 'Roboto', sans-serif;
  --font-noto-sans: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

  //inner
  --inner: 90%;
  --inner-sp: 90%;
  --inner-max: 1080px;

  // font-sizes
  --fz-primary: 1.125rem;
  --fz-half: .5rem;

  // text colors
  --clr-main: #009140;
  --clr-txt-sub: #c4d100;
  --clr-body: #3c3939;
  --clr-dark-blue: #101560;
  --clr-link: #333;
  --clr-wht: #fff;
  --clr-blk: #000;

  // background colors
  --bg-main: #009140;
  --bg-sub: #F3F6CC;
  --bg-wht: #fff;
  --bg-off_wht: #dcedfc;
  --bg-blk: #000;

  //button colors
  --btn-bg-main: #3C3939;
  --btn-bg-main-hvr: #009140;
  --btn-bg-cta: var(--clr-main);
  --btn-bg-cta-hvr: #096331;
  --btn-bg-cta-red: #dc559a;
  --btn-bg-cta-red-hvr: #D71379;
  --btn-clr-main: #fff;
  --btn-clr--sec: #fff;

  //line
  --btn-clr-line: #c3d000;

  //emergence
  --btn-clr-emr: #ff6d41;
  --btn-clr-emr-hvr: #d85128;

  --btn-clr-wht: #333;
  --btn-bg-wht: #fff;
  --btn-clr-wht-hvr: #fff;
  --btn-bg-wht-hvr: var(--clr-main);


  // border colors
  --brd-main: #009140;

  // animation
  --bezier: cubic-bezier(0.4, 0.16, 0.34, 0.94);
  --bezier-out: cubic-bezier(0.03, 0.57, 0.34, 0.94);
  --transit-default: all 0.4s ease-out;

  //shadow
  --shadow-defalut: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
}

// SASS Maps

$z-index: (
  "header" : 9999,
  "top": 9990,
  "aside" : 75,
  "middle" : 50,
  "main" : 1,
  "base" : 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160, // 2560px
  lg: 120, // 1920px
  pc: 85.375, // 1366px
  med: 67.5, // 1080px
  sp: 47.9375, // 767px
  sm: 40, // 640px
  xs: 34.375, // 500px
  se: 21.25, // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  'blue': (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  'red': (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  'green': (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  )
);

$insurance_color: (
  '01': #007c3e,
  '02': #cf263b,
  '03': #eac600,
  '04': #00469d,
  '05': #ff6d41,
  '06': #b0a056,
  '07': #bd2aa4,
  '08': #00a2e0,
);

$insurance_color_bg: (
  '01': #f0fac1,
  '02': #ffe9ec,
  '03': #ffffe6,
  '04': #e6f8f8,
  '05': #fef4ea,
  '06': #f3f3dc,
  '07': #fdf7fd,
  '08': #f1ffff,
);