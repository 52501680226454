@use '../abstracts' as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 96px;
  background-color: transparent;
  z-index: z(header);
  transition: var(--transit-default, all 0.4s ease-out);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: 24px;
  }
  @include mq(med) {
    height: 64px;
    & > .inner {
      padding-left: 18px;
    }
    &.gnav_active {
      background-color: #fff;
    }
  }
}
.header.header-sm {
  background-color: rgba(255, 255, 255, 0.93);
}
.header:not(.header-sm) .header--logo img[class=""] {
  opacity: 0;
  transition: .2s;
  @include mq(med,min) {
    .header-sm &,
    body:not(.home) & {
      opacity: 1;
    }
  }
  @include mq(med) {
    opacity: 0;
  }
}
.header--logo {
  width: min(318px, 18.6vw);
  position: relative;
  z-index: 2;
  @include mq(sp) {
    width: 210px;
  }
}
.header--logo-wh {
  position: absolute;
  top: 0;
  left: 0;
  transition: .2s;
  @include mq(med,min) {
    .header-sm &,
    body:not(.home) & {
      opacity: 0;
    }  
  }
}
.header--logo a,
.footer--logo a {
  display: block;
}
.header--logo a:hover,
.footer--logo a:hover {
  opacity: 0.7;
}

@include mq(med, min, ps) {
  .header-sm {
    height: 96px;
  }
}
@include mq(med) {
  .header--logo img {
    width: auto;
    max-height: 40px;
  }
}

/* gnav
********************************************** */
.gnav {
  font-size: rem(15);
}
.gnav a {
  display: flex;
  @include center-flex;
}
.gnav--link {
  padding: 0 min(20px, 0.85vw);
}
.gnav--link[href=""] {
  cursor: default; 
  pointer-events: none;
}
.gnav--menu {
  z-index: 5;
}
.gnav--cta {
  z-index: 10;
}
@include mq(med,min) {
  .head_sub_nav--wrap {
    display: block;
    width: 100%;
    position: fixed;
    left: 0;
    padding: 20px 0 40px;
    z-index: 100;
    transition: var(--transit-default, all 0.4s ease-out);
    opacity: 0;
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.93);
  }
  //コラムを非表示
  #menu-item-menu-item-4031 {
    display: none;
  }
}
.header .menu-item-has-children {
  position: relative;
  padding-right: 8px;
  @include mq(med,min) {
    &::after {
      content: '';
      position: absolute;
      right: 4px;
      top: -4px;
      bottom: 0;
      margin: auto;
      display: block;
      width: 8px;
      height: 8px;
      border-right: 1px solid var(--clr-main);
      border-bottom: 1px solid var(--clr-main);
      transform: rotate(45deg);
    }
  }
}
.head_sub_nav {
  display: flex;
  justify-content: center;
}
.head_sub_nav a {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 8px;
  border-bottom: 1px dotted var(--bg-main);
  margin: 0 40px;
  &::before {
    content: '';
    display: block;
    width: 17px;
    height: 17px;
    background-image: url('../images/common/nav_arrow.svg');
    margin-left: 8px;
  }
}

/* ---CTA --- */
.cta_tel a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.4;
  .page-contact & {
    align-items: center;
  }
  .page-contact & span {
    color: #fff;
  }
}
.cta_tel a > span {
  display: block;
}
.gnav--tel,
.gnav--contact {
  margin-left: 8px;
}
.gnav--tel a {
  display: flex !important;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  padding: 0 24px;
}
.header-sm .gnav--tel a {
  background-color: transparent;
}
.gnav--contact a {
  position: relative;
  overflow: hidden;
  width: min(168px, 11vw);
  height: min(152px, 10vw);
  min-width: auto;
  border-radius: 0 0 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  background-color: var(--btn-bg-cta-red, #fff);
  &:hover {
    background-color: var(--btn-bg-cta-red, #fff);;
  }
}
.gnav--contact svg {
  fill: #fff;
  margin-right: 8px;
}
.gnav--cta .tel {
  font-size: min(28px, 1.8vw);
}
.gnav--cta .hours {
  font-size: 0.875rem; /* 14px */
}
.gnav--sub_cta {
  position: absolute;
  right: 0;
  a {
    display: flex;
    flex-direction: column;
    border-right: 0;
    width: min(136px, 9.5vw);
    height: min(136px, 9.5vw);
    background-color: var(--btn-clr-line);
    border: 2px solid var(--btn-clr-line);
    border-radius: 16px 0 0 16px;
    font-size: fzr(18);
    line-height: 1.3;
    overflow: hidden;
  }
  img, span {
    transition:  var(--transit-default);
  }
}
.gnav--sub_cta,
.gnav--contact {
  a::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 19%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: scale(0.5);
    opacity: 0;
    transition: transform .3s ease-out, opacity .2s;
    border-radius: 24px;
    transform-origin: center center;
  }
  a:hover::before { 
    transform: scale(1.5);
    opacity: 1;
  }
}
.gnav--line {
  right: 0;
  bottom: -200px;
  a {
    background-color: var(--btn-clr-line);
    border: 2px solid var(--btn-clr-line);
  }
}
.gnav--emr {
  right: 0;
  bottom: -344px;
  a {
    background-color: var(--btn-clr-emr);
    border: 2px solid var(--btn-clr-emr);
    color: #fff;
  }
}
.header-sm {
  .gnav--sub_cta {
    a {
      width: 86px;
    }
    span {
      font-size: rem(14);
    }
  }
  .gnav--line {
    img {
      width: 50px;
    }
  }
  .gnav--emr {
    img {
      width: 33px;
    }
  }
}
@include mq(med, min, ps) {
  .gnav,
  .gnav--menu,
  .gnav--menu li,
  .gnav--tel ul,
  .gnav--tel li,
  .gnav--menu a,
  .gnav--tel a {
    height: 100%;
  }
  .gnav a {
    position: relative;
  }
  .gnav {
    display: flex;
  }
  .gnav_btn,
  .tel_btn {
    display: none;
  }
  .gnav--link {
    text-align: center;
  }
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):not([href=""]):hover,
  .gnav--menu > li.current-menu-item > .gnav--link {
    color: var(--clr-main);
  }
  .gnav--menu > li > .gnav--link:not([href=""])::after,
  .head_sub_nav > li > .gnav--link::after
   {
    content: '';
    position: absolute;
    top: 2.5em;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: calc(100% - 10px);
    height: 1px;
    background-color: var(--clr-main);
    transform: scale(0, 1);
    transform-origin: right top;
    transition: transform .3s;
  }
  .head_sub_nav > li > .gnav--link::after {
    width: 100%;
    bottom: -4px;
  }
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover::after,
  .gnav--menu > li.current-menu-item > .gnav--link::after,
  .head_sub_nav > li > .gnav--link:hover::after {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  .gnav_sub_toggle {
    display: none;
  }
  /* hover */
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a:hover {
    opacity: 0.7;
  }
}
@include mq(pc) {
  .gnav--contact  {
    position: absolute;
    bottom: -150px;
    right: 0;
  }
  .gnav--contact a {
    border-radius: 8px 0 0 8px;
    width: 130px;
    height: 128px;
    padding-top: 8px;
    span {
      font-size: rem(18);
    }
  }
  .gnav--sub_cta {
    a {
      border-radius: 8px 0 0 8px;
      width: 86px;
      height: 108px;
    }
    span {
      font-size: rem(14);
    }
  }
  .gnav--line {
    bottom: -266px;
    img {
      width: 50px;
    }
  }
  .gnav--emr {
    bottom: -382px;
    img {
      width: 33px;
    }
  }
}
@include mq(med) {
  .gnav,
  .gnav_overlay {
    position: fixed;
    top: 64px;
    right: 0;
    height: calc(100% - 64px);
    opacity: 0;
    pointer-events: none;
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .gnav_overlay {
    top: 0;
    height: 100%;
  }
  .gnav {
    width: min(480px,85%);
    margin: 0;
    padding: 24px 7%;
    overflow: scroll;
    background-image: url(../images/common/bg_menu_sp.jpg);
    background-size: cover;
    z-index: 300;
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
  }
  .gnav_overlay {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .gnav.active,
  .gnav_overlay.active {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav.active {
    transform: translateX(0);
  }
  .gnav--menu,
  .gnav--cta {
    display: block;
  }
  .gnav--menu  {
    margin-bottom: 24px;
    border-bottom: 1px dotted var(--clr-main);
  }
  .gnav--menu li {
    position: relative;
    display: block;
    width: 100%;
  }
  .gnav--menu li:not(:last-child) {
    border-bottom: 1px dotted var(--clr-main, #009140);
  }
  .gnav--menu a {
    display: block;
    padding: 18.5px 16px;
  }
  .gnav--menu li:not(.menu-item-has-children) a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    width: 16px;
    height: 16px;
    background-image: url(../images/common/icon-arrow-list.svg);
    background-size: cover;
  }
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-main, #009140);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
    h4 {
      display: none;
    }
  }
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .header .menu-item-has-children {
    padding: 0;
  }
  .head_sub_nav {
    display: block;
  }
  .head_sub_nav a {
    padding-left: 32px !important;
    border: none;
    margin: 0;
  }
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    right: -14px;
    width: 64px;
    height: 64px;
    transition: var(--transit-default, all 0.4s ease-out);
    color: var(--clr-main, #009140);
  }
  #menu-item-menu-item-3265 .head_sub_nav--wrap .gnav_sub_toggle {
    //お取り扱い商品の中トグル
    display: none;
  }
  .gnav_sub_toggle::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 16px;
    height: 16px;
    background-image: url(../images/common/icon-arrow-plus.svg);
    background-size: cover;
  }
  .gnav_sub_toggle.active::before {
    background-image: url(../images/common/icon-arrow-minus.svg);
  }
  .head_sub_nav {
    position: relative;
  }
  .tel_btn,
  .gnav_btn {
    position: fixed;
    top: 0;
    width: 64px;
    height: 64px;
    z-index: 9999;
  }
  .tel_btn {
    right: 64px;
  }
  .tel_btn a {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: var(--btn-bg-cta-red);
    border-radius: 0 0 0 8px;
    padding-top: 4px;
  }
  .tel_btn img {
    width: 26px;
    height: 26px;
    margin-bottom: 2px;
  }
  .tel_btn p {
    font-size: sprem(12);
  }
  .gnav_btn {
    right: 0;
    transition: var(--transit-default, all 0.4s ease-out);
    background-color: var(--clr-main);
    border-left: 1px solid #fff;
  }
  .gnav_btn.active {
    background-color: #e6f8f8;
  }
  .gnav_btn--lines {
    position: relative;
    width: 24px;
    height: 16px;
  }
  .gnav_btn--lines span {
    transition: var(--transit-default, all 0.4s ease-out);
    box-sizing: border-box;
  }
  .gnav_btn--lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    .active & {
      background-color: var(--clr-main);
    }
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
  }
  .gnav_btn--lines span:nth-of-type(2) {
    top: 7px;
  }
  .gnav_btn--lines span:nth-of-type(3) {
    bottom: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(7px) rotate(-45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-7px) rotate(45deg);
  }

  /* ---CTA --- */
  .gnav--cta li:not(:last-child) {
    margin-bottom: 16px;
  }
  .gnav--tel {
    margin-left: 0;
    text-align: center;
  }
  .gnav--tel a {
    border: 1px solid var(--clr-main);
    border-radius: 100px;
    align-items: center;
    padding: 12px;
    margin-bottom: 6px;
    span.tel {
      font-size: sprem(20);
    }
    .hours {
      display: none;
    }
  }
  .gnav--contact {
    margin-left: 0;
    margin-bottom: 0 !important;
  }
  .gnav--contact a {
    padding: 10px 16px;
    margin-left: auto;
    margin-right: auto;
  }
  .gnav--cta.cta-sp {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    a:not([href^="tel"]) {
      flex-direction: row;
      height: 86px;
      span {
        text-align: left;
        font-size: sprem(14);
      }
    }
    .gnav--tel {
      grid-column: span 2;
    }
    .gnav--contact {
      grid-column: span 2;
      margin-bottom: 0;
      img {
        margin-bottom: 0;
        margin-right: 8px;
      }
      a span {
        font-size: sprem(16);
      }
    }
    .gnav--line {
      img {
        width: 44px;
        margin-bottom: 0;
        margin-right: 8px;
      }
    }
    .gnav--emr {
      img {
        width: 32px;
        margin-bottom: 0;
        margin-right: 8px;
      }
    }
  }
  /* subnav */
  .gnav--sp_sub {
    display: grid;
    grid-template-columns: auto auto;
    justify-items: baseline;
    grid-row-gap: 8px;
    margin-bottom: 20px;
    li a::before  {
      content: '-';
      color: var(--clr-main);
      margin-right: 8px;
    }
  }

}
@include mq(sp) {
  .gnav--menu a {
    padding: 19px 16px;
  }
  .gnav--contact svg {
    width: 16px;
    height: 11px;
  }
}