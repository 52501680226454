@use '../abstracts' as *;
@use 'sass:math';

/* --- font-size --- */
// フォントサイズを8pxから140pxまでREMで生成
@for $i from 8 to 140 {
  .fz-#{$i} { font-size: math.div($i, 16) + rem}
}
@include mq(sp) {
  @for $i from 8 to 40 {
    .fz-sp-#{$i} { font-size: math.div($i, 14) + rem}
  }
}

/* txt
**************************************** */
.txt-lg { font-size: 1.125rem; /* 18px */ }
.txt-sm { font-size: .875rem; /* 14px */ }
.txt-wh,
.txt-wh * { color: var(--clr-wht, #fff); }
.txt-main,
.txt-main * { color: var(--clr-main, #009140); }
.txt-sub,
.txt-sub * { color: var(--clr-txt-sub, #eee); }


/* --- font --- */
.font-jp {
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
}
.font-jp-b {
  font-family: var(--font-jp-b, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W6", "Hiragino Kaku Gothic ProN", sans-serif);
  font-weight: 700;
}
.font-jp-en { //混植
  font-family: var(--font-jp-en);
}
.font-jp-en-b { //混植
  font-family: var(--font-jp-en-b);
  font-weight: 700;
}
.font-en {
  font-family: var(--font-en, 'Roboto', sans-serif);
  font-weight: 500;
}

/* txt
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}
.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}
.txt-underline {
  text-decoration: underline;
}

@include mq(sp) {
  .txt-lg { font-size: 1.071rem; /* 15px */ }
  .txt-sm { font-size: .929rem; /* 13px */ }
  .txt-ctr-pc,
  .txt-rgt-pc { text-align: left; }
}

/* letter-spacing
**************************************** */
.ltr-default {
  letter-spacing: var(--ltr-space-default);
}
.ltr-ttl {
  letter-spacing: var(--ltr-space-ttl);
}