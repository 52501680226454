@use '../abstracts' as *;

/* ##############################################################################

    SINGLE

############################################################################## */

  /* --- ページタイトルがない場合 --- */
  .single .container {
    padding-top: 120px;
    @include mq(sp) {
      padding-top: 0;
    }
  }

  /* --- アイキャッチ --- */
  .eyecatch {
    margin-bottom: 2rem;
  }

  /* --- 目次 --- */
  div#ez-toc-container {
    padding: 24px;
    background: #fafafa;
  }
  .ez-toc-title-container {
    margin-bottom: 10px;
  }
  div#ez-toc-container p.ez-toc-title {
    color: #000;
    font-weight: bold;
    font-size: 1.25rem;
  }
  #ez-toc-container.counter-hierarchy ul {
    font-size: 1.0625rem;
  }
  #ez-toc-container.counter-hierarchy ul li {
    margin-top: 8px;
  }
  #ez-toc-container.counter-hierarchy ul ul {
    margin-left: 1rem;
  }
  #ez-toc-container.counter-hierarchy ul ul a {
    position: relative;
    display: inline-block;
    padding-left: 1rem;
  }
  #ez-toc-container.counter-hierarchy ul ul a::before {
    content: "";
    position: absolute;
    top: .65rem;
    left: 0;
    width: .6rem;
    height: 0;
    border-bottom: 1px solid;
  }

/* pass
**************************************** */
  #pass p {
    margin-bottom: 1em;
  }
  #pass input[name="post_password"] {
    padding: 5px;
    border: solid 1px #aaa;
    outline: none;
  }
  #pass input[type="submit"] {
    padding: 4px 16px;
    letter-spacing: .15em;
    background-color: #ddd;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: var(--transit-default, all .4s ease-out);
  }
  #pass input[type="submit"]:hover {
    opacity: .7;
  }

/* column
**************************************** */
.single-column .post--info {
  width: 100%;
}
@include mq(sp) {
  .single-column .section_ttl {
    font-size: sprem(20);
  }
  .single-column .inner {
    width: 95%;
    padding-top: 24px;
  }
  .single-column .side_column {
    width: 90%;
    margin: auto;
  }
}

/* blog--share
**************************************** */
  .blog--share {
    margin-top: 4rem;
  }
  .blog--share_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: .375rem; /* 6px */
    @include mq(sp) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .blog--share_list a {
    display: flex;
    @include center-flex;
    height: 40px;
    padding: .5em;
    line-height: 1;
    font-size: .75rem; /* 12px */
    font-weight: bold;
    border: 1px solid #ddd;
  }
  .blog--share_list li {
    &.twitter a { color: #2BA1F2; }
    &.facebook a { color: #2477F2; }
    &.line a { color: #27C754; }
    &.pocket a { color: #EF4056; }
    &.linkedin a { color: #2867B2; }
    &.hatena a { color: #29A4DE; }
  }
  .blog--share_list img {
    margin-right: 10px;
  }
  .blog--share_list a:hover {
    opacity: .5;
  }

/* blog--related
**************************************** */
  .posts_scroll {
    overflow: auto;
    padding-bottom: 2rem;
  }
  .posts_scroll .post {
    display: block;
    width: 46%;
    flex-shrink: 0;
    @include mq(sp) {
      width: 70%;
      margin-bottom: 0;
    }
  }
  .posts_scroll .post:not(:last-child) {
    margin-right: 2rem;
  }
  .posts_scroll .post--img {
    margin-bottom: 1rem;
    width: 100%;
    height: auto;
  }
  .posts_scroll .post--img::before {
    padding-top: 66.666%;
  }
  .posts_scroll .post--txt {
    overflow: hidden; 
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; 
  }
  .posts_scroll::-webkit-scrollbar {
    height: 6px;
  }
  .posts_scroll::-webkit-scrollbar-thumb {
    background-color: var(--clr-main);
  }
  .posts_scroll::-webkit-scrollbar-track-piece {
    background-color: #eee;
  }

/* wp-pagenavi
**************************************** */
  .wp-pagenavi {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    @include mq(sp) {
      margin-top: 24px;
    }
  }
  .wp-pagenavi .pages {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
  }
  .wp-pagenavi a {
    position: relative;
    padding: .5em 1em;
    background-color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .wp-pagenavi a:hover {
    opacity: .7;
  }
  .wp-pagenavi a {
    color: #fff;
  }
  .wp-pagenavi > *:not(.pages) {
    max-width: calc(100% / 2.6);
  }
  .archive .wp-pagenavi > *:not(.pages) {
    display: flex;
    justify-content: center;
    align-items: center;
    @include rect(40);
    @include mq(sp) {
      @include rect(32);
    }
  }
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    width: 40px;
  }
  .wp-pagenavi > *:not(.pages) {
    margin-bottom: 4px;
  }
  .wp-pagenavi > *:not(.pages):not(:last-child) {
    margin-right: 4px;
  }
  .wp-pagenavi .extend {
    width: 24px !important;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-top: 2px solid;
    border-right: 2px solid;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    width: 8px;
    height: 8px;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .first::after {
    transform: rotate(-135deg);
  }
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .last::after {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="prev"]::before {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="next"]::before {
    transform: rotate(-135deg);
  }
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before {
    left: -6px;
    @include mq(sp) {
      left: -5px;
    }
  }
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    right: -6px;
    @include mq(sp) {
      right: -5px;
    }
  }

@include mq(sp) {
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    width: 32px;
  }  
}

/* voice
**************************************** */
.single-voice .main_column {
  margin-right: 0;
}
.single-voice .post--info--txt{
  width: 50%;
  margin-right: 5%;
  table {
    width: 100%;
  }
  table th,
  table td {
    font-size: rem(14);
    padding: 0.7em;
  }
  table th {
    width: 130px;
  }
}
.single-voice .eyecatch {
  width: 45%;
  flex-shrink: 0;
  img {
    width: 100%;
    aspect-ratio: 5/4;
    object-fit: cover;
    border-radius: 24px;
  }
  &::before {
    content: '';
    position: absolute;
    background-image: url(../images/index/txt_voice.svg);
    display: block;
    width: 180px;
    height: 90px;
    top: -60px;
    left: -10px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
.voice--content {
  .inner {
    background-color: #ffffff;
    box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 25%);
    padding: 80px;
    border-radius: 24px;
  }
}
@include mq(sp) {
 .single-voice .post--info {
    display: flex;
    flex-direction: column-reverse;
  } 
  .single-voice .post--ttl {
    font-size: sprem(21);
    margin-bottom: 24px;
  }
  .single-voice .post--info--txt {
    width: 100%;
  }
  .single-voice .eyecatch {
    width: 100%;
    &::before {
      width: 123px;
    }
  }
  .voice--content .inner {
    padding: 32px 30px;
  }
  .voice--hero {
    padding-top: 64px;
    margin-bottom: 64px;
  }
  .single-voice .blog--related h2  {
    font-size: sprem(16);
    margin-bottom: 16px;
  }
  .single-voice .blog--related th {
    min-width: 80px;
  }
  .single-voice .posts_scroll .post {
    width: 88%;
  }
}

/* news
**************************************** */
@include mq(sp) {
  .single-news .bg-dot_circle {
    background-image: none;
  }
}