@use '../abstracts' as *;

/* feature
 ############################### */
 @include mq(sp) {
   .page-feature .ttl-01 img {
     width: 140px;
   }
 }


@include mq(sp) {
  .page-customer #section-03 .column-2 .lps_parts--child  {
    display: flex;
    flex-direction: row;
    .column_item {
      width: 48%;
      background-color: transparent;
      background-image: none;
    }
    .text {
      padding: 0;
    }
    .editor-ttl2 span {
      font-size: sprem(20);
    }
   }
}

/* company
 ############################### */
 @include mq(sp,min) {
   .page-company #section-01 .ttl-01 {
    max-width: 1400px;
   }
   .page-company #section-01::after {
    display: none;
   }
 }

@include mq(sp) {
  // .page-company #section-01 {
  //   display: none;
  // }
  // .page-company #section-02 {
  //   // margin-top: 110px;
  // }
}

/* thanks
 ############################### */
 @include mq(med,min) {
  .page-thanks .page_wrapper .page_ttl .page_ttl-jp {
    font-size: min(3.5rem, 2.5vw);
  }
 }

 /* 404
 ############################### */
 @include mq(med,min) {
  .page-404 .page_wrapper .page_ttl .page_ttl-jp {
    font-size: min(3.5rem, 2.5vw);
  }
  .page-404 .section_pdg {
    padding-top: 120px;
  }
 }
 @include mq(sp) {
  .page-404 .page_wrapper .page_ttl .page_ttl-jp {
    font-size: 1.5rem;
  }
 }