@use '../abstracts' as *;

/* Wysiwyg editor
 **************************************** */
.mce-content-body ol {
  list-style: decimal;
}
.mce-content-body ul:not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body ul:not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin: 0;
  border-bottom: none;
  padding: 0;
  margin-left: 2em;
  padding-top: .5em;
}
.mce-content-body ul li {
  list-style: none;
}
.mce-content-body ul li::before {
  content: '';
  position: absolute;
  top: 1em;
  left: -1.3em;
  @include rect(10);
  border: 1px solid var(--clr-main, #009140);
  background-color: var(--clr-main, #009140);
}
.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: #fff;
}
.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}
.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}
.mce-content-body hr {
  border-color: #009140;
  border-style: dotted;
}
.mce-content-body .box {
  padding: 24px 32px;
  background-color: var(--bg-sub);
  border-radius: 16px;
  line-height: 2;
}
.mce-content-body .box:not(:first-child) {
  margin-top: 2em;
}
.mce-content-body .box:not(:last-child) {
  margin-bottom: 2em;
}
.mce-content-body .box-wh {
  background-color: var(--bg-wht, #fff);
  border: 0;
}
.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}
.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}
.mce-content-body .aligncenter {
  @include auto-margin;
  margin-bottom: 1em;
}
.mce-content-body img.aligncenter {
  display: block;
}
.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}
.mce-content-body p {
  letter-spacing: .1em;
}
.mce-content-body p:not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body strong {
  font-weight: bold;
}
.mce-content-body blockquote {
  margin: 1em 0;
  padding: 15px 40px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: #f8f8f8;
}
.mce-content-body blockquote p { margin-bottom: 0; }
.mce-content-body blockquote::before,
.mce-content-body blockquote::after {
  content: '';
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: .25;
}
.mce-content-body blockquote::before {
  background-image: url('../images/common/icon-quote_left.svg');
  top: 13px;
  left: 10px;
}
.mce-content-body blockquote::after {
  background-image: url('../images/common/icon-quote_right.svg');
  bottom: 13px;
  right: 10px;
}
.mce-content-body a {
  text-decoration: underline;
}
.mce-content-body a:hover {
  text-decoration: none;
}
.mce-content-body .footer_text small {
  display: block;
  margin-top: 8px;
  text-align: right;
}
.mce-content-body table {
  border: none;
  height: auto !important;
}
.mce-content-body table:not(:first-child) { margin-top: 1em; }
.mce-content-body table:not(:last-child) { margin-bottom: 1em; }
.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}
.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid #D3D3D3;
  vertical-align: middle;
}
.mce-content-body th {
  min-width: 120px;
  text-align: left;
}
.mce-content-body sup {
  font-size: .75rem; /* 12px */
  color: #555;
}
.bold {
  font-weight: bold;
  color: var(--clr-main, #009140);
}

/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  vertical-align: bottom;
}
.mce-content-body iframe:not(:first-child) {
  margin-top: 2em;
}
.mce-content-body iframe:not(:last-child) {
  margin-bottom: 2em;
}

/* table scroll */
.table-sp-scroll {
  white-space: nowrap;
}
.scroll {
  overflow-x: scroll;
  margin-bottom: 1em;
  -webkit-overflow-scrolling: touch;
}
.scroll table { margin: 0; }
.scroll--cap {
  text-align: right;
  margin: 1em 0 0 !important;
  opacity: .25;
  font-size: .875rem; /* 14px */
}

@include mq(sp) {
  .mce-content-body p:not([class]) {
    text-align: left !important;
  }
  .mce-content-body .alignright,
  .mce-content-body .alignleft  {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 15px;
  }
  .mce-content-body blockquote::before {
    top: 0;
    left: 0;
  }
  .mce-content-body blockquote::after {
    bottom: 0;
    right: 0;
  }
  .mce-content-body ul li::before {
    @include rect(8);
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: .5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body .box {
    padding: 24px;
  }
  .mce-content-body iframe[src*="google.com/maps"] {
    aspect-ratio: 4/3;
  }
}
/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor {
  padding: 24px !important;
  max-height: 100vh !important;
  overflow-y: scroll !important;
}

@include mq(sp) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
/* add quicktag
*************************************************** */
/* 見出し */
.editor-ttl:not(:first-child),
.editor-ttl2:not(:first-child),
.editor-ttl3:not(:first-child) {
  margin-top: 1em;
}
.editor-ttl:not(:last-child),
.editor-ttl2:not(:last-child),
.editor-ttl3:not(:last-child) {
  margin-bottom: .5em;
}
.editor-ttl {
  @include heading-1;
}
.editor-ttl2 {
  @include heading-2;
}
.editor-ttl3 {
  @include heading-3;
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: .875rem; /* 14px */
  color: #999;
}
.txt-attention::before {
  content: '※';
}
.txt-attention a {
  color: #999;
  text-decoration: underline;
}


/* テーブル */
.mce-content-body table:not(.table1) th,
.table2 th {
  background-color: var(--clr-main, #009140);
  color: var(--clr-wht, #fff);
}
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
}
.table1 th {
  color: var(--clr-main, #009140);
  font-size: 1.125rem; /* 18px */
  font-family: var(--font-jp-en-b);
  font-weight: 700;
  border-bottom: 1px solid var(--clr-main);
}
.table1 td {
  border-bottom: 1px dotted #9D9C9C;
}
.mce-content-body .table2 {
  border: 1px solid var(--clr-main);
  border-collapse: separate;
  border-radius: 20px; 
  overflow: hidden;
  th {
    font-family: var(--font-jp-en-b);
    font-weight: 700;
  }
  th, td {
    border: none;
  }
  td {
    background-color: #fff;
  }
  tr:not(:last-child) th {
    border-bottom: 1px dotted #007C3E;
  }
  tr:not(:last-child) td {
    border-bottom: 1px dotted #9D9C9C;
  }
}
.table2 tr:first-child td:first-child,
.table2 tr:first-child tr:first-child { /* 最初のtr(行) かつ、最初の td(列) つまり、1行目1列目に適用*/
  border-radius: 20px 0 0 0; /* 左上から指定してゆく */
}
.table2 tr:first-child td:last-child,
.table2 tr:first-child tr:last-child { /* 最初のtr(行) かつ、最後の td(列) つまり、1行目3列目に適用*/
  border-radius: 0 20px 0 0;
}
.table2 tr:last-child td:first-child,
.table2 tr:last-child tr:first-child { /* 最後のtr(行) かつ、最初の td(列) つまり、3行目1列目に適用*/
  border-radius: 0 0 0 20px;
}
.table2 tr:last-child td:last-child,
.table2 tr:last-child tr:last-child { /* 最後のtr(行) かつ、最後の td(列) つまり、3行目3列目に適用*/
  border-radius: 0 0 20px 0;
}


@include mq(sp) {

  /* テーブル */
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #D3D3D3;
  }
  .table1.table-sp-block td::before {
    display: none;
  }

  /* 注意書き */
  .txt-attention {
    font-size: .929rem; /* 13px */
  }
}