@use '../abstracts' as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  display: block;
  transform: translateY(-50%);
  transition: var(--transit-default, all .4s ease-out);
}
.icon-new_tab {
  @include rect(14);
}
.btn .icon-new_tab {
  right: 19px;
}
.icon-pdf {
  width: 27px;
  height: 12px;
}
.btn .icon-pdf {
  left: 15px;
  @include mq(sp) {
    left: 12px;
  }
}
.lps_sec {
  position: relative;
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

@media screen and (max-width: 767px) {
  .btn .icon-new_tab {
    right: 24px;
  }
}

/* padding
********************************************** */
.lps_sec:nth-child(odd) {
  padding-top: 160px;
  padding-bottom: 160px;
}
.lps_sec:nth-child(even) {
  padding-top: 40px;
  padding-bottom: 40px;
}
@include mq(sp) {
  .lps_sec:nth-child(odd) {
    padding-top: 110px;
    padding-bottom: 110px;
  }
  #section-01 {
    padding-top: 64px;
  }
}

/* section_add_img
********************************************** */

.lps_sec .section_add_img_wrap {
  position: relative;

  margin-top: 64px;
  img {
    width: 100%;
    height: min(800px,35vw);
    object-fit: cover;
  }
}

/* 奇数セクション（しろ）に画像をつけたとき */
.lps_sec:nth-child(odd).section_add_img {
  padding-bottom: 0;
}
.lps_sec:nth-child(odd).section_add_img + .lps_sec:nth-child(even) {
  //緑セクションの上ブラシを消す
  padding-top: 80px;
  &::before {
    display: none;
  }
}

/* 偶数セクション（みどり） に画像をつけたとき*/
.lps_sec:nth-child(even).section_add_img {
  //緑セクションの下ブラシを消す
  padding-bottom: 0;
  &::after {
    display: none;
  }
}
.lps_sec:nth-child(even).section_add_img + .lps_sec:nth-child(odd) {
  padding-top: 80px;
}

/* ttl
********************************************** */
.lps_sec .ttl-01.ttl_add_illust:not(.txt-ctr)::before {
  content: '';
  display: block;
  width: 200px;
  height: 40px;
  background-image: url(../images/common/ttl_img_01.png);
  background-size: cover;
  margin-bottom: 32px;
  @include mq(sp) {
    width: 120px;
    height: 30px;
    background-image: url(../images/common/ttl_img_01.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    margin-bottom: 12px;
  }
}
.lps_sec .ttl-01.ttl_add_illust.txt-ctr::before {
  content: '';
  display: block;
  width: 41px;
  height: 41px;
  background-image: url(../images/common/ttl_logomark.svg);
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}
.lps_parts .img-detail::before {
  padding-top: 66.666%;
}
.lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
  margin-top: 80px;
  @include mq(sp) {
    margin-top: 40px;
  }
}
.lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
  margin-top: 40px;
  @include mq(sp) {
    margin-top: 16px;
  }
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: 24px;
}
.lps_parts--column .btn,
.lps_parts--slide .btn {
  text-align: center;
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 40px;
  @include mq(sp) {
    margin-bottom: 16px;
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
}

/* next-page
********************************************** */
.lps_sec.next_page:nth-child(odd) {
  padding: 180px 0 80px;
  @include mq(sp) {
    padding: 120px 0 40px;
  }
}
.lps_sec.next_page:nth-child(even) {
  padding: 40px 0 80px;
  @include mq(sp) {
    padding: 20px 0 40px;
  }
}