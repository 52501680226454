
@use '../abstracts' as *;

  /* --- anchor --- */
  .anchor {
    position: absolute;
    top: -140px; /* 固定ヘッダーの高さ */
    left: 0;
    pointer-events: none;
    @include mq(med){
      top: -64px; /* 固定ヘッダーの高さ */
    }
  }
  .lps_parts--insurance .anchor {
    top: -180px;
  }