@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
  justify-content: flex-end;
  align-items: flex-end;
  color: var(--clr-main);
  letter-spacing: var(--ltr-space-ttl);
  height: 356px;
  @include mq(sp) {
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    background-image: url(../images/common/ttl_bg_logomark.png);
    background-size: 64px 90px;
    background-repeat: no-repeat;
    background-position: right 34px top 0px;
    padding-top: 16px;
    & > .inner {
      width: var(--inner-sp);
      text-align: center;
      padding-bottom: 32px;
      padding-top: 0;
    }
    br {
      display: none;
    }
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 880px;
    height: 4px;
    background-image: url(../images/common/ttl_line.svg);
    background-position: center;
    @include mq(sp) {
      width: 100%;
      height: 4px;
      background-repeat: no-repeat;
      background-image: url(../images/common/ttl_line_sp.svg);
    }
  }
}
.page_ttl--bg-wrap {
  height: 500px;
  @include mq(sp) {
    height: 120px;
  }
}
.page_ttl--bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-size: cover;
  background-position: center;
}
.page_ttl-jp {
  font-size: rem(56); /* 48px */
  margin-bottom: 0.2em;
  @include mq(sp) {
    font-size: sprem(24); /* 28px */
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: 1.125rem; /* 18px */
  font-family: var(--font-en, "Montserrat", sans-serif);
  @include mq(sp) {
    font-size: sprem(14); /* 16px */
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03 {
  letter-spacing: 0.1em;
  font-family: var(--font-jp-en-b);
}
.ttl-01 {
  font-size: min(3rem, 3vw); /* 48px */
  color: var(--clr-blk);
  line-height: 1.33;
  @include mq(sp) {
    font-size: 2.143rem; /* 30px */
  }
}
.ttl-02 {
  font-size: 2.5rem; /* 40px */
  @include mq(sp) {
    font-size: sprem(26); /* 28px */
  }
}
.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: 2.5rem; /* 40px */
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.ttl-03 {
  font-size: 1.75rem; /* 28px */
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03 {
  position: relative;
  z-index: 1;
}
.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03:not(:last-child) {
  margin-bottom: 1em;
}
.ttl-04 {
  font-size: 1.5rem; /* 24px */
}
.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: 1.5rem; /* 24px */
  @include mq(sp) {
    font-size: 1.714rem; /* 24px */
  }
}
.heading-1 {
  @include heading-1;
}
.heading-2 {
  @include heading-2;
}
.heading-3 {
  @include heading-3;
}

.ttl-side_border {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem(32);
  color: var(--clr-main);
  font-family: var(--font-jp-b);
  margin-bottom: 1em;
  &::before,
  &::after {
    content: '';
    height: 1px;
    width: auto;
    flex-grow: 1;
    border: 1px dashed var(--clr-main);
  }
  &::before { margin-right: 1em; }
  &::after { margin-left: 1em; }
}