@use '../abstracts' as *;
@use 'sass:math';

.tr-default {
  transition: var(--transit-default);
}
.tr-bezier-out {
  transition-timing-function: var(--bezier-out);
}

/* --- duration --- */
// durationを3秒まで作成
@for $i from 1 to 30 {
  .tr-#{$i} { transition-duration: math.div($i, 10) + s}
}

/* --- delay --- */
// delayを3秒まで作成
@for $i from 1 to 30 {
  .tr-delay-#{$i} { transition-delay: math.div($i, 10) + s}
}

.anime-fadein-btm {
  transform: translateY(70px);
  opacity: 0;
  @include transition-base(all,.8s);
  @include easing-bezier();
  .active &,
  &.active {
    transform: translateY(0);
    opacity: 1;
  }
}

.will-tra {
  will-change: transform;
}

