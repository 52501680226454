@use "../abstracts" as *;

/* bg
********************************************** */
.bg-main {
  background-color: var(--bg-main, #009140);
}
.bg-sub {
  background-color: var(--bg-sub);
}
.bg-wh {
  background-color: var(--bg-wht, #fff);
}
.bg-pattern {
  background-image: url(../images/common/pt_bg_02.jpg);
}
.bg-pattern-brush {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -120px;
    left: 0;
    width: 100%;
    height: calc(100% + 120px);
    background-image: url(../images/common/pt_bg_02.jpg);
    -webkit-mask-image: url(../images/common/pt_bg_mask.png), linear-gradient(180deg, rgba(255, 255, 255, 0) 180px, rgb(0, 0, 0) 180px, rgb(0, 0, 0) 100%);
    -webkit-mask-mode: luminance;
    mask-image: url(../images/common/pt_bg_mask.png), linear-gradient(180deg, rgba(255, 255, 255, 0) 180px, rgb(0, 0, 0) 180px, rgb(0, 0, 0) 100%);
    mask-mode: luminance;
    z-index: -1;
    @include mq(sp) {
      top: -72px;
      -webkit-mask-image: url(../images/common/pt_bg_mask.png), linear-gradient(180deg, rgba(255, 255, 255, 0) 50px, rgb(0, 0, 0) 50px, rgb(0, 0, 0) 100%);
      mask-image: url(../images/common/pt_bg_mask.png), linear-gradient(180deg, rgba(255, 255, 255, 0) 50px, rgb(0, 0, 0) 50px, rgb(0, 0, 0) 100%);
      -webkit-mask-size: 100% auto, auto;
      mask-size: 100% auto, auto;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }
  }
}
.bg-dot_circle {
  background-image: url(../images/common/bg-dot-line-circle.svg);
  background-position: top center;
  background-size: 115% auto;
  background-repeat: repeat-y;
}

/* box
********************************************** */
.box-shadow {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 25%);
  padding: 80px;
  border-radius: 24px;
  @include mq(sp) {
    padding: 32px;
    border-radius: 16px;
  }
}

/* loops
********************************************** */
.lps_sec:nth-child(2n):not([style^="background"]) {
  background-image: url(../images/common/pt_bg_02.jpg);
}
.lps_sec:nth-child(2n) {
  z-index: 2;
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 145px;
    background-size: 1265px 100%;
    background-position: center;
  }
  &::before {
    background-image: url(../images/common/ttl_sec-top_gr_01.png);
    top: -144px;
    @include mq(sp) {
      height: 80px;
      top: -79px;      
    }
  }
  &::after {
    background-image: url(../images/common/ttl_sec-btm_gr_01.png);
    bottom: -144px;
    @include mq(sp) {
      height: 80px;
      bottom: -79px;
    }
  }
  &:last-child::after {
    display: none;
  }
}
.lps_sec:nth-child(4n) {
  &::before {
    background-image: url(../images/common/ttl_sec-top_gr_02.png);
  }
  &::after {
    background-image: url(../images/common/ttl_sec-btm_gr_02.png);
  }
}
//奇数セクションの背景ON・OFF
.lps_sec.section_add_bg:nth-child(odd):not(:first-child) {
  background-image: url(../images/common/bg-trees.png);
  background-repeat: repeat-y;
  background-position: right 16px center;
  background-size: min(320px,16.6vw) auto;
  @include mq(sp) {
    background-image: none;
    // background-image: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 12%, rgba(255,255,255,1) 21%, rgba(255,255,255,1) 100%),url(../images/common/bg-trees.png);
    // background-repeat: no-repeat, repeat-y;
    // background-position: center, top 34px right 24px;
    // background-size: cover, 80px auto;
  }
}
//セクション区切りのイラストON・OFF
.lps_sec .lps_sec-illust {
  position: absolute;
  top: 0;
  width: 90%;
  height: auto;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1080px;
  transform: translateY(-100%);
  @include mq(sp) {
    top: -10px;
  }
}
.lps_sec:nth-child(odd) .lps_sec-illust {
  display: none;
}