@use '../abstracts' as *;

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 8px;
}
.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: 20px 40px;
  border-radius: 100px;
  width: fit-content;
  max-width: 100%;
  min-width: 256px;
  color: var(--btn-clr-main, #fff);
  text-align: center;
  background-color: var(--btn-bg-main, #009140);
  font-family: var(--font-jp-en-b);
  font-weight: 700;
  @include mq(sp) {
    max-width: 100%;
  }
}  
.flex > .btn a { max-width: 100%; }
.btn.txt-ctr a {
  @include auto-margin;
}
.btn a:hover {
  background-color: var(--btn-bg-main-hvr, #015691);
}
.btn a:where(:not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]):not([target="_blank"]))::before {
  content: '';
  position: absolute;
  top: calc(50% - 4px);
  right: 20px;
  display: block;
  @include rect(8);
  border-top: solid 1px;
  border-right: solid 1px;
  transform: rotate(45deg);
  transition: var(--transit-default, all .4s ease-out);
}
.btn svg {
  fill: var(--btn-clr-main, #fff);
}
.btn.btn-next_page a {
  max-width: 100%;
  width: 100%;
  border-radius: 0;
  text-align: left;
  border-radius: 100px;
}

/* utilities */
.btn.btn-w100 a { width: 100%; }
.btn.btn-radius a { border-radius: 8px; }


/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #333);
  background-color: var(--btn-bg-wht, #fff);
}
.btn-wh svg {
  fill: var(--btn-clr-wht, #333);
}
.btn-wh a:hover {
  color: var(--btn-clr-wht-hvr, #fff);
  background-color: var(--btn-bg-wht-hvr, #333);
}
.btn-wh a:hover svg {
  fill: var(--btn-clr-wht-hvr, #fff);
}
@include mq(sp) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}

/* --- btn-red --- */
.btn-red a {
  color: #fff;
  background-color: var(--btn-bg-cta-red, #fff);
}
.btn-red svg {
  fill: #fff;
}
.btn-red a:hover {
  color: #fff;
  background-color: var(--btn-bg-cta-red-hvr, #333);
}
.btn-red a:hover svg {
  fill: #fff;
}
.btn-red.icon {
  a::after {
    content:'';
    position: absolute;
    top: 0;
    left: 20px;
    bottom: 0;
    margin: auto;
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(../images/common/icn_logomark.svg);
    background-size: cover;
  }
}

/* --- btn-big --- */
.btn-big a {
  width: 688px;
  height: 120px;
  padding: 42px 40px;
  font-size: 24px;
  border-radius: 8px;
  &:not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"])::before {
    width: 12px;
    height: 12px;
    border-top: solid 2px;
    border-right: solid 2px;
  }
  @include mq(med) {
    padding: 22px 40px;
    font-size: sprem(24);
    height: 80px;
  }
  @include mq(sp) {
    width: 100%;
    padding: 23px 30px;
    font-size: sprem(18);
    height: 72px;
  }
}

/* --- btn-emr --- */
.btn-emr a {
  background-color: var(--btn-clr-emr);
  color: #fff;
  &::after {
    content:'';
    position: absolute;
    top: 0;
    left: 20px;
    bottom: 0;
    margin: auto;
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(../images/common/icn_info.svg);
    background-size: cover;
  }
  &:hover {
    background-color: var(--btn-clr-emr-hvr);
  }
}