@use '../abstracts' as *;

/* lps_parts--insurance
********************************************** */
.insurance_color-01 .lps_parts--child { background-color: map-get($insurance_color_bg, '01'); }
.insurance_color-02 .lps_parts--child { background-color: map-get($insurance_color_bg, '02'); }
.insurance_color-03 .lps_parts--child { background-color: map-get($insurance_color_bg, '03'); }
.insurance_color-04 .lps_parts--child { background-color: map-get($insurance_color_bg, '04'); }
.insurance_color-05 .lps_parts--child { background-color: map-get($insurance_color_bg, '05'); }
.insurance_color-06 .lps_parts--child { background-color: map-get($insurance_color_bg, '06'); }
.insurance_color-07 .lps_parts--child { background-color: map-get($insurance_color_bg, '07'); }
.insurance_color-08 .lps_parts--child { background-color: map-get($insurance_color_bg, '08'); }

.insurance_color-01 .lps_parts--child .insurance_icon { background-color: map-get($insurance_color, '01'); }
.insurance_color-02 .lps_parts--child .insurance_icon { background-color: map-get($insurance_color, '02'); }
.insurance_color-03 .lps_parts--child .insurance_icon { background-color: map-get($insurance_color, '03'); }
.insurance_color-04 .lps_parts--child .insurance_icon { background-color: map-get($insurance_color, '04'); }
.insurance_color-05 .lps_parts--child .insurance_icon { background-color: map-get($insurance_color, '05'); }
.insurance_color-06 .lps_parts--child .insurance_icon { background-color: map-get($insurance_color, '06'); }
.insurance_color-07 .lps_parts--child .insurance_icon { background-color: map-get($insurance_color, '07'); }
.insurance_color-08 .lps_parts--child .insurance_icon { background-color: map-get($insurance_color, '08'); }

.insurance_color-01 .lps_parts--child::before { background-image: url(../images/common/pt_insurance_01.jpg); }
.insurance_color-02 .lps_parts--child::before { background-image: url(../images/common/pt_insurance_02.jpg); }
.insurance_color-03 .lps_parts--child::before { background-image: url(../images/common/pt_insurance_03.jpg); }
.insurance_color-04 .lps_parts--child::before {
   background-image: url(../images/common/pt_insurance_04.jpg); 
   background-size: 250px auto;
   background-position: center;
}
.insurance_color-05 .lps_parts--child::before { background-image: url(../images/common/pt_insurance_05.jpg); }
.insurance_color-06 .lps_parts--child::before { background-image: url(../images/common/pt_insurance_06.jpg); }
.insurance_color-07 .lps_parts--child::before { background-image: url(../images/common/pt_insurance_07.jpg); }
.insurance_color-08 .lps_parts--child::before { background-image: url(../images/common/pt_insurance_08.jpg); }

.lps_parts--insurance .lps_parts--child {
  position: relative;
  margin-top: 120px;
  padding: 100px 5% 5%;
  border-radius: 16px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 56px;
    border-radius: 16px 16px 0 0;
  }
  .insurance_icon {
    position: absolute;
    top: -90px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 176px;
    height: 176px;
    border-radius: 50%;
    margin: auto;
    img {
      width: 100px;
      height: 100px;
    }
    @include mq(sp) {
      top: -46px;
      width: 124px;
      height: 124px;
      img {
        width: 72px;
        height: 72px;
      }
    }
  }

  .insurance_item_wrap {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: auto; 
    gap: min(32px,2vw);
    @include mq(sp) {
      grid-template-columns: 1fr; 
      grid-template-rows: auto; 
      gap: 24px;
    }
  }

  @include mq(sp) {
    h4 {
      font-size: sprem(28);
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  .insurance_item {
    a {
      position: relative;
      display: block;
      border: 1px solid var(--clr-main);
      color: var(--clr-main);
      font-size: min(rem(24), 1.5vw);
      border-radius: 100px;
      background-color: #fff;
      padding: 16px 40px 16px 32px;
      line-height: 1.3;
      margin-bottom: 16px;
      overflow: hidden;
      span {
        display: block;
        width: 100%;
        text-align: center;
        margin: auto;
        position: relative;
        z-index: 2;
      }
      small {
        font-size: 86%;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100px;
        display: block;
        width: 100%;
        height: 100%;
        background-color: var(--bg-sub);
        transform: translateX(-100%);
        transition: all .3s cubic-bezier(0.03, 0.57, 0.34, 0.94), opacity .15s linear;
        opacity: 0;
      }
      &:hover::before {
        opacity: 1;
        transform: translateX(0);
      }
      svg {
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        fill: var(--clr-main);
      }
      @include mq(sp) {
        font-size: sprem(18);
        margin-bottom: 16px;
      }
    }
    p {
      padding: 0 min(32px,2vw);
    }
  }
}