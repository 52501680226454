@use '../abstracts' as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: min(80px,5vw);
  overflow: hidden;
}

/* --- post --- */
.post {
  position: relative;
}
.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.post--date {
  color: #aaa;
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
} 
.post--txtarea {
  width: 100%;
}
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.813rem; /* 13px */
  background-color: var(--clr-main, #009140);
  color: #fff;
  padding: 0.25em 1em;
  float: left;
  white-space: nowrap;
  margin: 2px;
  border-radius: 4px;
}
.cat_list a:hover {
  opacity: 0.7;
}

/* eyecatch hover */
.hover_overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: rgba(0,145,64,.5);
  font-size: 120%;
  letter-spacing: .2em;
  opacity: 0;
  transition: all .3s ease-out;
}
.hover_overlay span {
  transform: translateY(15px);
  opacity: 0;
  transition: all .5s cubic-bezier(.3, .45, 0, 1.03);
}
@media screen and (min-width: 768px) {
  /* PC hover */
  .hover_overlay_item:hover .hover_overlay {
    opacity: 1;
  }
  .hover_overlay_item:hover .hover_overlay span {
    transform: translateY(0);
    opacity: 1;
  }
  .hover_overlay_item:hover .hover_overlay span:nth-child(2) { transition-delay: .05s; }
  .hover_overlay_item:hover .hover_overlay span:nth-child(3) { transition-delay: .1s; }
  .hover_overlay_item:hover .hover_overlay span:nth-child(4) { transition-delay: .15s; }
}

@include mq(1100) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
}

/* --- post-blog --- */
.post-blog {
  margin-bottom: 2.2rem;
  @include mq(sp) {
    align-items: flex-start;
  }
}
.post-blog .post--img {
  width: 240px;
  height: 180px;
  flex-shrink: 0;
  margin-right: 32px;
  @include mq(sp) {
    width: 35%;
    height: auto;
    margin-right: 1.5em;
    &::before {
      padding-top: 66.6%;
    }
  }
}
.post-blog:hover .post--ttl a {
  color: var(--clr-main);
}
@include mq(sp) {
  .post-blog .post--ttl {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .post-blog .post--txt {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .post-blog .post--info {
    margin-bottom: 4px;
  }
  .post-blog .cat_list a {
    font-size: sprem(8);
  }
}



/* side_column
**************************************** */
.side_column {
  width: 264px;
  @include mq(1100) {
    width: 100%;
  }
}
.side_section:not(:last-child) {
  margin-bottom: 64px;
}
.side--ttl {
  display: flex;
  align-items: center;
  letter-spacing: 0.2em;
  line-height: 1;
  margin-bottom: 16px;
}
.side--ttl small {
  letter-spacing: 0.15em;
  display: block;
  line-height: 1;
  margin-top: 1px;
  margin-left: 8px;
}

/* --- list --- */
.side--list a {
  display: block;
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: 16px;
}
.posts-side .post--img {
  margin-right: 16px;
  width: 64px;
  height: 64px;
  img {
    border-radius: 8px;
  }
}
.posts-side .txtarea {
  flex: 1;
}
.posts-side .post--date {
  margin-bottom: 0;
}
.posts-side .post--ttl {
  line-height: 1.4;
}

/* --- archive --- */
.side_column .archive_list,
.side_column .cat-item a {
  background-color: #fff;
  padding: 4px 18px;
  border-radius: 16px;
}
.side_column .cat-item a:hover {
  color: var(--clr-main);
}
.side_column .archive_list {
  margin-bottom: 8px;
}
.side_column .cat-item {
  margin-bottom: 8px;
}
.side_column .archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
}
.side_column .archive_list--ttl::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--trans-default, all 0.4s ease-out);
  border-color: var(--clr-main);
}
.side_column .archive_list--ttl.active::after {
  transform: rotate(315deg);
}
.side_column .archive_month {
  display: none;
  a {
    padding-left: 8px;
  }
  a:hover {
    color: var(--clr-main);
  }
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 1;
}
.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}
.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.archive-pulldown .archive_list a:hover {
  background-color: #eee;
}
.archive-pulldown .archive_list--label {
  margin-right: 8px;
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: #fff;
  border: 1px solid #ccc;
  font-family: var(--font-primary, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}


/* column
**************************************** */
.post-type-column {
  background-image: url(../images/common/pt_bg_02.jpg);
}
.post-type-column .main_column {
  padding: min(100px, 6.66vw);
  background-color: #fff;
}
.posts-column .post {
  padding-bottom: 48px;
  padding-top: 48px;
  border-bottom: 1px solid #e5e5e5;
}
.posts-column .post:first-child {
  border-top: 1px solid #e5e5e5;
}
.posts-column .post--txtarea {
  flex: 1;
  word-break: break-word;
}
.posts-column .post--img {
  width: 280px;
  margin-right: 40px;
}
.posts-column .post--img::before {
  padding-top: 75%;
}

@include mq(sp) {  
  .posts-column .post {
    display: block;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .posts-column .post--img {
    padding-left: 0;
    @include auto-margin;
    margin-bottom: 16px;
    text-align: center;
  }
  .posts-column .post--img img {
    width: auto;
    max-width: 100%;
  }
  .archive-column .inner {
    width: 95%;
  }
  .archive-column .side_column {
    width: 90%;
    margin: auto;
  }
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: #fff;
}
@include mq(sp) {
  .archive-news .bg-dot_circle {
    background-image: none;
  }
}

/* --- news_list --- */
.news_list .post {
  padding-bottom: 16px;
  padding-top: 16px;
  margin-bottom: 0;
  border-bottom: 1px solid #dedede;
  @include mq(sp) {
    display: block;
  }
}
.news_list .post--info {
  margin-right: 32px;
  @include mq(sp) {
    margin-bottom: 8px;
    margin-right: 0;
  }
}
.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main, #009140);
}
.news_list .post--link:hover svg {
  fill: var(--clr-main, #009140);
}
.news_list .post--info.flex .post--date + .cat_list {
  margin-left: 16px;
}
.news_list .cat_list a:nth-child(n+2) {
  display: none;
}
.news_list .has_link::before {
  content:'';
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 8px;
  height: 8px;
  border-right: 2px solid #ccc;
  border-top: 2px solid #ccc;
  transform: rotate(45deg);
}
.news_list .has_link::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  display: block;
  width: 0;
  height: 1px;
  opacity: 0;
  background-color: var(--clr-main);
  transition: width .3s ease-out, opacity .3s;
}
.news_list .has_link:hover::after {
  width: 100%;
  opacity: 1;
}

/* voice
**************************************** */
.archive-voice .main_column {
  margin-right: 0;
  overflow: visible;
}
@include mq(sp) {
  .voice--read {
    width: 100%;
  }
}

/* catlist */
.voice--catlist {
  position: relative;
  width: min(880px, 100%);
  margin: 0 auto 64px;
  box-shadow: var(--shadow-defalut);
  border-radius: 16px;
  padding: 40px;
  h3 {
    flex-shrink: 0;
    margin-right: 24px;
    margin-top: 2px;
    img {
      width: 32px;
      margin-right: 10px;
    }
  }
  a {
    display: inline-block;
    background-color: var(--bg-sub);
    padding: 0.1em 0.8em;
    margin: 4px;
    border-radius: 4px;
    border: 1px solid var(--bg-sub);
    &:hover {
      border: 1px solid var(--clr-main);
    }
  }
  @include mq(sp) {
    padding: 20px;
    h3 {
      margin-bottom: 8px;
    }
    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      // display: block;
      width: 20px;
      height: 20px;
      background-color: var(--clr-main);
      clip-path: url(#svg_plus);
      display: none;
    }
  }
}

/* --- post-voice --- */
.post-voice .post--img::before {
  padding-top: 80%;
}
.post-voice table th {
  font-size: rem(16);
  padding: 1em 0.5em 1em 0;
  @include mq(1200) {
    font-size: rem(14);
  }
}
.post-voice h2 {
  @include mq(1200) {
    font-size: rem(20);
  }
}
.post-voice .post--ttl em,
.single-voice .post--ttl em {
  padding: 4px;
  background-color: var(--clr-txt-sub);
  color: var(--clr-body);
}
.post-voice .no_img {
  background-image: url(../images/common/pt_bg_02.jpg);
}
.post-voice table {
  width: 100%;
  @include mq(1200) {
    font-size: rem(14);
    th,td {
      padding: 1em 0.5em;
    }
  }
}