@use "../abstracts" as *;

.icon-logo-list {
  position: relative;
  &::before {
    content: '';
    display: inline-block;
    width: 19px;
    height: 19px;
    background-image: url(../images/common/icon-logo-list.svg);
    flex-shrink: 0;
  }
  &.flex::before {
    position: relative;
    top: 0.15em;
    margin-right: 8px;
  }
}
.icon-arrow-list {
  position: relative;
  &::after {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(../images/common/icon-arrow-list.svg);
  }
}

.box-line {
  border: 1px solid var(--clr-main);
  padding: 1.8vw 2.5vw;
  border-radius: 16px;
  @include mq(sp) {
    padding: 3.1vw 4vw;
  }
}

/* insurance color
 ############################### */

@for $i from 1 through 8 {
  .color0#{$i} { color: insurance_color($i);}
  .bgc0#{$i} { background-color: insurance_color($i); }
} 